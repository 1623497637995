import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import authStore from '~/stores/authStore';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(({ spacing }) =>
    createStyles({
        root: {
            paddingTop: spacing(4),
            paddingBottom: spacing(4)
        },
        bottomMargin1: {
            paddingBottom: spacing(5)
        },
        bottomMargin2: {
            paddingBottom: spacing(1)
        }
    })
);

const CodeSnippets = () => {
    const classes = useStyles();
    const { lang } = authStore;

    return (
        <Container maxWidth="md">
            <div className={classes.root}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="h5" component="h1" align="center">
                            {lang === 'en' && 'THE MOST MULTIFUNCTIONAL API AT THE MOMENT'}
                            {lang === 'ru' && 'САМЫЙ МНОГОФУНКЦИОНАЛЬНЫЙ API'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardHeader
                                avatar={<MonetizationOnIcon fontSize="large" color="secondary" />}
                                title={lang === 'en' ? 'CREATED FOR BUSINESS' : 'Создан для вашего бизнеса'}
                            />
                            <CardContent>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                    className={classes.bottomMargin1}
                                >
                                    {lang === 'en' &&
                                        `Chat API is designed to create chat bots and integrate WhatsApp with business systems: CRM, ERP or a website.`}
                                    {lang === 'ru' &&
                                        `Chat API предназначен для создания чат ботов и интеграции WhatsApp и Telegram с бизнес системами: CRM, ERP или веб-сайтами.`}
                                </Typography>

                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                    className={classes.bottomMargin1}
                                >
                                    {lang === 'en' &&
                                        `Chat API is already used for many projects: sending and reading messages from the CRM-system, recording
                                        in the beauty salon, sending details of the vacancy or chat-bot with promotional codes.`}
                                    {lang === 'ru' &&
                                        `Chat API уже используется во многих проектах: отправка и прочтение сообщений с CRM-систем, запись в салоны красоты, 
                                         отправка информации о вакансиях или чат боты с промоушен кодами.`}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardHeader
                                avatar={<DeveloperModeIcon fontSize="large" color="secondary" />}
                                title={lang === 'en' ? 'API FOR DEVELOPERS' : 'API для разработчиков'}
                            />
                            <CardContent>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                    className={classes.bottomMargin2}
                                >
                                    {lang === 'en' &&
                                        `Create a chat bot in a couple of hours or integrate into 6,000 dialogs per day in PHP, JavaScript,
                                    Google Docs, Python, Java, C# or even VBA.`}
                                    {lang === 'ru' &&
                                        `Создание чат бота за несколько часов или работа с 6,000 диалогами в день на PHP, JavaScript,
                                    Google Docs, Python, Java, C# и даже VBA.`}
                                </Typography>

                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                    className={classes.bottomMargin2}
                                >
                                    {lang === 'en' &&
                                        `The official closed API WhatsApp WEB under the hood works stably and does not fall when you update
                                    WhatsApp - this is our main difference from other solutions.`}
                                    {lang === 'ru' &&
                                        `Официальный закрытый API WhatsApp WEB под капотом работает стабильно и не падает при обновлении
                                     WhatsApp - это наше главное отличие от других решений.`}
                                </Typography>

                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                    className={classes.bottomMargin2}
                                >
                                    {lang === 'en' &&
                                        `Guides for building a Whatsapp bot on PHP, on Python, on Node.JS, on C# or on Java. Bot in
                                    conjunction with Google Sheets.`}
                                    {lang === 'ru' &&
                                        `Руководства по созданию бота Whatsapp на PHP, на Python, на Node.JS, на C# or на Java. Бот
                                    в сочетании с Google Таблицами.`}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
};

export default observer(CodeSnippets);
