const codes = [
    {
        title: 'php',
        language: 'php',
        code: `
// First of all - set a webhook to URL like http://your_website.com/my_webhook_url.php

// Parse a webhook data
$data = json_decode(file_get_contents('php://input'), true);
foreach($data['messages'] as $message){ // Echo every message
    // Handle every message here
    // Add to the database or generate a response
}
`
    },
    {
        title: 'node.js',
        language: 'javascript',
        code: `
// First of all - set a webhook to URL like http://your_website.com/my_webhook_url

// Require Express JS и Body Parser for JSON POST acceptance
var app = require('express')();
var bodyParser = require('body-parser');
app.use(bodyParser.json());

// Handle POST request
app.post('/my_webhook_url', function (req, res) {
    var data = req.body; // New messages in the "body" variable
    for (var i = 0; i < data.messages.length; i++) { // For each message
        var message = data.messages[i];
        console.log(message.author + ': ' + message.body); //Send it to console
    }
    res.send('Ok'); //Response does not matter
});

app.listen(80);
`
    }
];

export default codes;
