import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useDocStyles } from '~/components/Docs';

const Polite = () => {
    const classes = useDocStyles();

    return (
        <>
            <Container maxWidth="md" className={classes.docs}>
                <h1>Политика конфиденциальности</h1>
                <p>
                    Ваша конфиденциальность очень важна для нас. Мы хотим, чтобы Ваша работа в Интернет по возможности
                    была максимально приятной и полезной, и Вы совершенно спокойно использовали широчайший спектр
                    информации, инструментов и возможностей, которые предлагает Интернет.
                </p>

                <h4>Какие данные собираются на сайте</h4>

                <p>При регистрации Вы вносите свои Имя и адрес Электронной почты.</p>

                <h4>С какой целью собираются эти данные</h4>

                <p>Имя используется для вежливого обращения, а телефон и электронная почта для прямой связи с Вами.</p>

                <h4>Как эти данные используются</h4>

                <p>
                    Когда пользователь посещает веб-узел, на его компьютер записывается файл «cookie» (если пользователь
                    разрешает прием таких файлов). Если же пользователь уже посещал данный веб-узел, файл «cookie»
                    считывается с компьютера. Одно из направлений использования файлов «cookie» связано с тем, что с их
                    помощью облегчается сбор статистики посещения. Эти сведения помогают определять, какая информация,
                    отправляемая пользователям, может представлять для них наибольший интерес. Сбор этих данных
                    осуществляется в обобщенном виде и никогда не соотносится с личными сведениями пользователей.
                </p>

                <p>
                    Третьи стороны, включая компании Google, Yandex, показывают объявления нашей компании на страницах
                    сайтов в Интернете. Третьи стороны, включая компанию Google, Yandex используют cookie, чтобы
                    показывать объявления, основанные на предыдущих посещениях пользователем нашего сайта и интересах в
                    веб-браузерах. Пользователи могут запретить компаниям Google использовать cookie. Для этого
                    необходимо посетить специальную страницу компании Google по этому адресу:
                    http://www.google.com/privacy/ads/.
                </p>

                <p>
                    Вы можете в любой момент изменить настройки своего браузера так, чтобы браузер блокировал все файлы
                    cookies или оповещал об отправке этих файлов. Учтите при этом, что некоторые функции и сервисы не
                    смогут работать должным образом.
                </p>

                <h4>Как эти данные защищаются</h4>

                <p>
                    Для защиты Вашей личной информации мы используем административные, управленческие и технические меры
                    безопасности. Мы придерживаемся международных стандартов контроля, направленных на операции с личной
                    информацией, которые включают определенные меры контроля по защите информации, собранной в Интернет.
                    Наших сотрудников обучают понимать и выполнять эти меры контроля, они ознакомлены с нашим
                    Уведомлением о конфиденциальности, нормами и инструкциями.
                </p>

                <p>
                    Тем не менее, несмотря на то, что мы стремимся обезопасить Вашу личную информацию, Вы тоже должны
                    принимать меры, чтобы защитить ее. Мы настоятельно рекомендуем Вам принимать все возможные меры
                    предосторожности во время пребывания в Интернете. Организованные нами услуги и сайт предусматривают
                    меры по защите от утечки, несанкционированного использования и изменения информации, которую мы
                    контролируем. Несмотря на то, что мы делаем все возможное, чтобы обеспечить целостность и
                    безопасность своей сети и систем, мы не можем гарантировать, что наши меры безопасности предотвратят
                    незаконный доступ к этой информации хакеров сторонних организаций.
                </p>

                <h4>Разглашение личных сведений и передача этих сведений третьим лицам</h4>

                <p>Ваши личные сведения могут быть разглашены нами только в том случае, если это необходимо для:</p>

                <ul>
                    <li>
                        обеспечения соответствия предписаниям закона или требованиям судебного процесса в нашем
                        отношении;
                    </li>
                    <li>защиты наших прав или собственности;</li>
                    <li>
                        принятия срочных мер по обеспечению личной безопасности наших сотрудников или пользователей,
                        предоставляемых им услуг, а также обеспечению общественной безопасности;
                    </li>
                    <li>
                        в соответствии с обоснованными и применимыми требованиями закона или&nbsp;для исполнения
                        обязательств перед пользователем – только с его разрешения.
                    </li>
                </ul>

                <h4>Изменения политики конфиденциальности</h4>

                <p>
                    Обо всех изменениях настоящей политики конфиденциальности Вы будете уведомлены путем опубликования
                    информации на данной странице.
                    <br />
                    Для связи с администратором сайта по любым вопросам Вы можете обратиться через форму обратной связи.
                </p>

                <p>
                    Зарегистрировавшись на нашем сайте Вы соглашаетесь получать смс-уведомления о регистрации,
                    оформлении подписки, изменениях условий подписки и новостях сайта.
                </p>
            </Container>
        </>
    );
};

export default Polite;
