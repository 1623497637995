import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import authStore from '~/stores/authStore';

import Button from '@material-ui/core/Button';
import OrderModal from './OrderModal';
import adminStore, { PriceType } from '~/stores/adminStore';

const langLib = {
    en: {
        renew: 'Renew'
    },
    ru: {
        renew: 'Продлить'
    }
} as const;

const RenewButton = ({ count, createTime, priceType }: { count: number; priceType: PriceType; createTime: number }) => {
    const { lang, currentUser } = authStore;
    const lib = langLib[lang];
    const [showOrder, setShowOrder] = useState(false);
    const toggleShowOrder = () => {
        if (!showOrder) {
            adminStore.setPhoneNumbers(count);
            adminStore.setPriceType(priceType);
            adminStore.setCreateTime(createTime);
        } else {
            adminStore.setPhoneNumbers(0);
            adminStore.setPriceType('priceW');
            adminStore.setCreateTime(null);
        }
        setShowOrder(!showOrder);
    };

    return (
        <>
            <Button onClick={toggleShowOrder} color="secondary" variant={'contained'} size="small">
                {lib['renew']}
            </Button>
            {showOrder && <OrderModal onClose={toggleShowOrder} />}
        </>
    );
};

export default observer(RenewButton);
