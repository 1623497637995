import React, { ReactNode, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import AuthModal, { AuthModals } from './AuthModal';
import authStore from '~/stores/authStore';
import Button from '@material-ui/core/Button';

const SignUpBtn = ({ children }: { children: ReactNode }) => {
    const history = useHistory();

    const [open, setOpen] = useState(false);
    const handleToggleOpen = () => {
        setOpen(!open);
    };

    const { currentUser, lang } = authStore;
    if (currentUser) {
        return (
            <Button onClick={() => history.push('/admin')} color="secondary" variant="contained" size="large">
                {lang === 'en' ? 'Go to Admin Panel' : 'Перейти в Личный кабинет'}
            </Button>
        );
    }

    return (
        <>
            <span onClick={handleToggleOpen}>{children}</span>
            {open && <AuthModal type={AuthModals.signUp} onClose={handleToggleOpen} />}
        </>
    );
};

export default observer(SignUpBtn);
