import React, { ChangeEvent, useState } from 'react';
import validator from 'validator';
import { resetPassword } from '~/api/authApi';

import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

import { authMobileStyle, AuthModals } from '~/components/auth/AuthModal';
import authStore from '~/stores/authStore';
import AlreadyHaveAnAccount from '~/components/auth/AlreadyHaveAnAccount';

const langLib = {
    en: {
        sendPass: 'Send a password reset email to the given email address',
        emailIsInvalid: 'Email is required',
        send: 'Send'
    },
    ru: {
        sendPass: 'Отправить новый пароль на указанный адрес',
        emailIsInvalid: 'Укажите email',
        send: 'Отправить'
    }
} as const;

const LoginModal = ({ changeModalType }: { changeModalType: (type: AuthModals) => void }) => {
    const { lang } = authStore;
    const lib = langLib[lang];

    const classes = authMobileStyle();

    const [email, setEmail] = useState('');
    const handleEmail = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value.trim());
        setEmailIsInvalid(false);
    };
    const [isEmailInvalid, setEmailIsInvalid] = useState(false);
    const checkEmail = () => {
        const valid = validator.isEmail(email);
        setEmailIsInvalid(!valid);
        return valid;
    };

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [done, setDone] = useState(false);

    const handleSendRequest = async () => {
        if (checkEmail()) {
            setIsLoading(true);
            setError('');
            try {
                await resetPassword({ email });
                setDone(true);
            } catch (error) {
                setError(error?.message || error);
            }
        }
    };

    return (
        <DialogContent dividers>
            {error && <Alert severity="error">{error}</Alert>}
            {done && <Alert severity="success">Check your email to find a new password</Alert>}

            {!done && (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="body2">{lib['sendPass']}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            value={email}
                            onChange={handleEmail}
                            onBlur={checkEmail}
                            type="email"
                            error={isEmailInvalid}
                            helperText={isEmailInvalid ? lib['emailIsInvalid'] : ''}
                            label="Email *"
                            fullWidth
                            variant="standard"
                            autoComplete="off"
                            disabled={isLoading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.wrapper}>
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={isLoading}
                                fullWidth
                                onClick={handleSendRequest}
                            >
                                {lib['send']}
                            </Button>
                            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </Grid>
                    <AlreadyHaveAnAccount changeModalType={changeModalType} />
                </Grid>
            )}
        </DialogContent>
    );
};

export default LoginModal;
