import React from 'react';
import { observer } from 'mobx-react';

import adminStore from '~/stores/adminStore';
import authStore from '~/stores/authStore';

import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { prices, matchPrice } from './Calculator';

const langLib = {
    en: {
        numbers: 'Numbers',
        price: 'Price (30 days)',
        cost: 'Total Cost',
        from: 'from',
        selectThis: 'Select this'
    },
    ru: {
        numbers: 'Количество',
        price: 'за номер (30 дней)',
        cost: 'Сумма',
        from: 'от',
        selectThis: 'Выбрать'
    }
} as const;

const TablePrice = ({ extended }: { extended?: boolean }) => {
    const { phoneNumbers, priceType } = adminStore;
    const { lang } = authStore;
    const lib = langLib[lang];

    return (
        <Table size="medium">
            <TableHead>
                <TableRow>
                    <TableCell>{lib['numbers']}</TableCell>
                    {extended && (
                        <>
                            <TableCell align="right">Whatsapp</TableCell>
                            <TableCell align="right">Telegram</TableCell>
                            <TableCell align="right">
                                <Hidden xsDown>Whatsapp + Telegram</Hidden>
                                <Hidden smUp>Whapp+Tg</Hidden>
                            </TableCell>
                        </>
                    )}
                    {!extended && (
                        <>
                            <TableCell align="right">{lib['price']}</TableCell>
                            <TableCell align="right">{lib['cost']}</TableCell>
                            <Hidden xsDown>
                                <TableCell align="right" />
                            </Hidden>
                        </>
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {prices[lang].slice(1).map(({ [priceType]: price, priceT, priceW, priceWT, numbers }) => {
                    const isActive = matchPrice(lang, priceType, phoneNumbers) === price;

                    return (
                        <TableRow key={numbers} selected={isActive}>
                            <TableCell component="th" scope="row">
                                {lib['from']} <b>{numbers}</b>
                            </TableCell>
                            {extended && (
                                <>
                                    <TableCell align="right">{authStore.printCost(priceW)}</TableCell>
                                    <TableCell align="right">{authStore.printCost(priceT)}</TableCell>
                                    <TableCell align="right">{authStore.printCost(priceWT)}</TableCell>
                                </>
                            )}
                            {!extended && (
                                <>
                                    <TableCell align="right">{authStore.printCost(price)}</TableCell>
                                    <TableCell align="right">
                                        {authStore.printCost(Number((price * numbers).toFixed(2)))}
                                    </TableCell>
                                    <Hidden xsDown>
                                        <TableCell align="right">
                                            <Button
                                                onClick={() => adminStore.setPhoneNumbers(numbers)}
                                                color="primary"
                                                variant={isActive ? 'contained' : 'outlined'}
                                                size="small"
                                            >
                                                {lib['selectThis']}
                                            </Button>
                                        </TableCell>
                                    </Hidden>
                                </>
                            )}
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default observer(TablePrice);
