import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import LoginModal from './LogInModal';
import SignUpModal from './SignUpModal';
import ForgotPassModal from './ForgotPassModal';
import authStore from '~/stores/authStore';
import { observer } from 'mobx-react';

export enum AuthModals {
    signUp,
    logIn,
    forgotPass
}

export const authMobileStyle = makeStyles(({ spacing, palette }) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& > *': {
                marginLeft: spacing(0.5),
                marginRight: spacing(0.5)
            }
        },
        wrapper: {
            margin: spacing(1),
            position: 'relative'
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12
        }
    })
);

const styles = makeStyles(({ spacing, palette }) =>
    createStyles({
        closeButton: {
            position: 'absolute',
            right: spacing(1),
            top: spacing(1),
            color: palette.grey[500]
        },
        titleHeader: {
            display: 'flex',
            alignItems: 'center'
        }
    })
);

const langLib = {
    en: {
        signUp: 'Create an account',
        logIn: 'Log In',
        forgotPass: 'Recover your password'
    },
    ru: {
        signUp: 'Регистрация',
        logIn: 'Вход в аккаунт',
        forgotPass: 'Восстановление пароля'
    }
} as const;

const AuthModal = ({ onClose, type }: { onClose: () => void; type: AuthModals }) => {
    const { lang } = authStore;
    const lib = langLib[lang];

    const classes = styles();
    const [modalType, setModalType] = useState(type);

    return (
        <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle disableTypography>
                <Typography variant="h6" className={classes.titleHeader}>
                    {modalType === AuthModals.signUp && lib['signUp']}
                    {modalType === AuthModals.logIn && lib['logIn']}
                    {modalType === AuthModals.forgotPass && lib['forgotPass']}
                </Typography>
                <IconButton className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            {modalType === AuthModals.signUp && <SignUpModal changeModalType={setModalType} />}
            {modalType === AuthModals.logIn && <LoginModal changeModalType={setModalType} />}
            {modalType === AuthModals.forgotPass && <ForgotPassModal changeModalType={setModalType} />}
        </Dialog>
    );
};

export default observer(AuthModal);
