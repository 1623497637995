import React from 'react';

const TermsAndConditionsEn = () => {
    return (
        <>
            <h1>Terms and Conditions</h1>
            <b>Attention, this is a brief summary of important information. Read the entire document below</b>
            <ul>
                <li>
                    <p>Connect-Api is unofficial and independent of WhatsApp Inc;</p>
                </li>
                <li>
                    <p>You agree not to use the Connect-Api service for spam and bulk messaging;</p>
                </li>
                <li>
                    <p>
                        By authorizing the phone number (messenger account) in the Connect-Api service, you agree that
                        Connect-Api will have full access to the authorized account in the messenger;
                    </p>
                </li>
                <li>
                    <p>
                        Connect-Api has the right to block your access to the service in case of violation of the
                        "Public Agreement" or "Message Content Requirements";
                    </p>
                </li>
            </ul>
            <p>Public agreement</p>
            <p>
                1. General terms
                <br />
                1.1. Connect-api.com (Below will be used «developer») provides an opportunity for a capable individual
                who has joined this Agreement in his own interest, or who acts on behalf of and in the interests of a
                legal entity of the site (Below will be used "Customer") to use the services of the site
                https://connect-api.com/ and any subdomains thereof (Below will be used "Site") on the terms and
                conditions set forth in this User Agreement (Below will be used "Agreement"). The Agreement comes into
                force from the moment of the User's consent to its terms and conditions in the manner prescribed by
                paragraph 1.2 of the Agreement.
                <br />
                1.2. The text of the Agreement is offered to the Customer when registering on the Site. The Agreement
                shall come into force after the Customer agrees to its terms in the form of a confirmation of
                acquaintance. <br />
                1.3. Use of materials and services of the Site is regulated by the norms of the current international
                legislation and/or legislation of other countries (Below will be used "Current legislation")
                <br />
                1.4. This Agreement is a public offer. Getting access to the materials of the Site the Customer is
                considered to have joined the present Agreement and accepts all conditions of the present Agreement,
                Rules of use of a site and Requirements to advertising materials and the contents of sent messages in
                full without any exceptions and restrictions and undertakes to observe them in full. <br />
                1.5. The Site Administration has the right to unilaterally change the terms of this Agreement at any
                time without any special notice. Such changes come into force after 1 (one) working day from the moment
                of placing the new version of the Agreement on the site. In case of disagreement of the Customer with
                the changes made, the Customer shall be obliged to refuse access to the Site and stop using the
                materials and services of the Site.
            </p>
            <p>
                2. Terminology
                <br />
                2.1. The Site (Site) – is the connect-api.com website and all of its subdomains available on the
                Internet at www.connect-api.com.
                <br />
                2.2. Administration of the site (Administration) – a person authorized by the Developer to manage the
                Site, as well as other activities related to the use of the Site. <br />
                2.3. Service — set of services provided by the Developer to the Customer using the Site. <br />
                2.4. Login – a sequence of symbols identifying each Customer among other users of the Site. <br />
                2.5. Password – a sequence of characters known only to the Customer, stored on the Site in an encrypted
                form, used to identify the Customer. <br />
                2.6. Api — is a functionality and/or programming interface, which allows to implement in the existing
                Software, website, etc., messenger functions, expand and make them convenient for use.
                <br />
                2.7. Instance&nbsp;— Account received by the user under 1 phone number.
            </p>
            <p>
                3. Terms of use of the Site's services
                <br />
                3.1. The Customer is obliged to comply with the requirements of the legislation when using the Service,
                including the provisions of the legislation governing the protection of copyright and other intellectual
                property rights, protection of personal rights, religious and public beliefs, national dignity, the
                requirements of the legislation of other countries, as well as the norms of international law, and to
                use the Service solely for the transfer of information to users who have given their consent to receive
                it. The Customer undertakes to obtain in advance from the user to whom the message is to be sent -
                consent in any form, such as oral, written or primary message from the user to the Customer. <br />
                3.2.The Customer <b>undertakes not to use</b> the Developer's Service for (as well as not to facilitate
                possible attempts of such use of the Developer's Services by third parties):
            </p>
            <ul>
                <li>for spamming or Spam organization;</li>
                <li>for bulk messages;</li>
                <li>for send messages that may cause malfunction of the Developer's equipment;</li>
                <li>for transmitting inaccurate, confidential information;</li>
                <li>
                    for transfer of information that has been unlawfully obtained by the Customer and/or violates the
                    copyrights or related rights of third parties;
                </li>
                <li>
                    for transfer of materials that are illegal, harmful, threatening, insulting to morality, honor and
                    dignity, rights and legally protected interests of third parties, defamatory, defamatory,
                    propagandizing hatred, cruelty, violence and/or discrimination of people on racial, ethnic, sexual,
                    social grounds, contributing to inciting religious, racial or ethnic hatred, containing scenes of
                    violence or inhuman treatment of animals, etc;
                </li>
                <li>for sending messages via pornographic or other reprehensible messengers;</li>
                <li>
                    for sending messages via messengers that mislead users, such as those sent from someone else's name
                    or giving false information;
                </li>
                <li>
                    for transmission of information that violates the norms of the current legislation, as well as the
                    norms of international law;
                </li>
                <li>
                    for transfer of materials containing computer codes designed to violate, destroy or limit the
                    functionality of any computer or telecommunication equipment or programs for unauthorized access, as
                    well as serial numbers of commercial software products, logins, passwords and other means for
                    obtaining unauthorized access to paid Internet resources;
                </li>
                <li>
                    for distribution of political information, including information related to the collection of и\или
                    dissemination of political information, political agitation;
                </li>
                <li>for collecting and storing personal data of other users;</li>
                <li>for advertising alcohol, tobacco and drugs, as well as other illegal means;</li>
                <li>
                    for reduction of links / forms / generation of QR codes with content that is illegal, harmful,
                    threatening, defamatory, offends morality, infringes copyright, propagandizes hatred and/or
                    discrimination against people on racial, ethnic, sexual, religious, social grounds, contains insults
                    to specific individuals or organizations;
                </li>
                <li>
                    for reduction of links / forms / generation of QR codes with content that the Customer is not
                    entitled to make available under the Law or under any contractual relationship. And also, which
                    affects any patent, trademark, trade secret, copyright or other proprietary rights and / or
                    copyright and related rights of third parties.
                </li>
            </ul>
            <p>
                3.3. When registering, the customer can choose his own login to access the account. The customer is
                responsible for the security of his chosen password, as well as independently ensures the
                confidentiality of his password. <br />
                3.4. The Customer shall be solely responsible for all actions (as well as their consequences) within the
                framework or using the Service under the Customer's account, including cases of voluntary transfer of
                data by the Customer for access to the account to third parties on any terms and conditions (including
                contracts or agreements). In this case, all actions within the framework of or using the Service under
                the Customer's account are considered to be performed by the Customer himself. <br />
                3.5. The Administration reserves the right to make changes to the Service urgently and without prior
                notice to the Customer in cases where it is required to ensure normal provision of services (in
                particular, due to problems associated with the network and its security). <br />
                3.6. Responsibility for the origin, content and reliability of the information placed by the Customer
                using the Service, as well as for the compliance of such information with the requirements of applicable
                law, international law rests solely with the Customer. <br />
                3.7. If the Customer violates the terms of this Agreement and/or the requirements of the current
                legislation and/or international legislation, the Customer shall be fully and independently liable to
                the Developer and/or third parties for its actions related to the use of the Service, including if such
                actions will result in violation of the rights and legitimate interests of the Developer and/or third
                parties. <br />
                3.8. The Administration of the site has the right to block access to the Service to the Customer in case
                of violation by the Customer of the conditions provided by the Agreement and/or in case of violation of
                the current legislation and / or legislation of other countries, and / or international law. <br />
                3.9. The administration of the site reserves the right to unilaterally make changes in the tariff
                policy, with the subsequent publication of new tariffs on the site. Such changes come into force after 5
                (five) calendar days from the moment of placing new tariffs on the Site. In case of disagreement of the
                Customer with the changes made, he shall be obliged to give up access to the Site, stop using materials
                and services of the Site. <br />
                3.10. The Administration of the Site does not bear responsibility for all consequences which have been
                caused by violation of the present Agreement by the Customer. <br />
                3.11. The customer has the right to refuse to use Api, but by continuing to use the Developer's website,
                the customer automatically agrees to the use of Api. <br />
                3.12. The developer reserves the right to change the Api policy from time to time without prior notice.{' '}
                <br />
                3.13. The developer is not responsible for the content of external sites. <br />
                3.14. During registration on the Site the Customer undertakes to specify email as the main way of
                communication with him and not to use email, which does not belong to him. <br />
                3.15. The customer agrees that he or she must evaluate all risks associated with the use of Api and the
                Site, as well as the use of the content, including the reliability, completeness or usefulness of the
                content. <br />
                3.16. The Developer shall have the right to block the Customer's access to the system without any
                penalties if the latter uses an email or phone number that does not belong to him, and to withhold a
                fine from the Customer in the amount of the balance of the Customer's Electronic Virtual Account at the
                moment of termination of the Services. <br />
                3.17. The Developer is not obliged to view any content of any kind used by the Customer, and the
                Developer has the right (but not the obligation) at its own discretion to refuse to post or remove any
                content used by the Customer on the Developer's Website. <br />
                3.18. The Developer is not responsible for the content of the links / forms / QR codes, which the
                Customer creates with the help of the Developer's site and makes their publication.
                <br />
                3.19. The developer has the right to block / delete any links / forms at his discretion without giving
                any reasons. <br />
                3.20. The Customer agrees that when using Api and/or the Service and/or the Website, the Customer shall
                pay for the monthly use of the Service (as per the tariff). <br />
                3.21. When using Api and/or the Service and/or the Website, the Customer expressly agrees to the "Public
                Agreement" and the "Requirement for the Content of Messages sent by Proposed Messengers" published on
                the Website. <br />
                3.22. The customer gives his explicit, specific and informed consent to the use of the Developer's
                website or the websites linked to it by Api. <br />
                3.23. By registering on the site and/or using the Service/Api, the Customer has the possibility (but not
                the obligation) to authorize the phone number (account in the messenger) in his personal cabinet, and
                the Customer gives his unambiguous, concrete and full consent to the Developer's full access to the
                authorized account in the messenger. <br />
                3.24. When using the Service/Api/Site, the Customer undertakes not to violate WhatsApp, Telegram and
                Facebook rules by any actions. <br />
                3.25. The Developer shall have the right to block the Customer's access to the system in case of
                violation by the Customer of the rules of the "Public Agreement" and/or "Message Content Requirements"
                without applying any penalties to the Developer
                <br />
                <br />
                4. Terms of use of personal data
                <br />
                4.1. By joining the terms and conditions of this Agreement, the Customer agrees to the automated
                processing of the provided personal data for the purpose of concluding the contract, as well as its
                subsequent performance. <br />
                4.2. In cases directly provided for by the legislation, information on personal data may be transferred
                to third parties only upon prior written consent of the Parties.
                <br />
                <br />
                5. Other terms and conditions
                <br />
                5.1. This Agreement, the procedure of its conclusion and execution, as well as all possible disputes
                arising from this Agreement or related to it, not regulated by this Agreement, shall be governed by
                applicable law and / or international law and / or legislation of other countries.
                <br />
                5.2. If for any reason one or more of the provisions of this Agreement are held invalid or
                unenforceable, the validity or enforceability of the remaining provisions of the Agreement shall not be
                affected. <br />
                5.3. Nothing in the Agreement can be understood as the establishment between the Customer and the
                Developer of agency relations, partnership relations, joint activity relations, personal employment
                relations, or any other relations not expressly provided for in the Agreement. <br />
                5.4. The Customer confirms that he is familiar with all the provisions of this Agreement and
                unconditionally accepts them. <br />
                5.5. The provisions of this Agreement are binding for all previously registered Customers.
                <br />
                <br />
                6. Cost of the Service and payment procedure
                <br />
                6.1 The cost of Services is shown on the Site. <br />
                6.2 The cost of Services selected by the Customer shall be fixed in the Application for connection.
                Application for connection can be registration on the Site. <br />
                6.3 Payment is made monthly, in the form of 100% prepayment. It is possible to pay semi-annual and
                annual fees upon request of the invoice for payment. <br />
                6.4 The Developer has the right to change the cost of the Service unilaterally, subject to prior notice
                to the Customer 5 (five) days prior to the date of change in cost.
                <br />
                6.5 Payment is made by non-cash payment upon request of the invoice for payment or by electronic
                payment.
                <br />
                6.6 The date of payment is considered to be the date when the money is credited to the Developer's
                current account. <br />
                6.7 Following the results of month the Developer can give the certificate of the executed works.
            </p>
            <p>
                7. Communication with WhatsApp Inc. and guarantees
                <br />
                7.1 The Service and the connect-api.com Site are not supported or approved in any way by WhatsApp Inc
                (or any of its affiliates or subsidiaries). The Developer's Service, Api and the Site are informal and
                independent of WhatsApp Inc (or any of its affiliates or subsidiaries). The Customer understands all
                risks associated with the use of the Service/Site/Api.
                <br />
                7.2 The customer agrees that his account (and his phone number) may be blocked and/or banned by WhatsApp
                and/or WhatsApp Inc.
                <br />
                7.3&nbsp;The developer is not responsible for blocking or for the Customer's bank (his account and/or
                phone number(s)).&nbsp;
                <br />
                7.4 The developer is not responsible for the Customer's inability to get access to the service, to Api,
                to accounts, etc. for reasons related to the disruption of the Internet channel, equipment or software
                of the Customer.
                <br />
                7.5 The developer is not responsible for the Customer's inability to access the service, to Api due to
                software changes by WhatsApp Inc.
                <br />
                7.6 The Customer realizes that for the work of the Service (Api), the phone connected to the Service
                should always be connected to the Internet and should not be used for Whatsapp Web.
            </p>
            <p>
                REQUIREMENTS FOR THE CONTENT OF MESSAGES SENT BY PROPOSED MESSENGERS
                <br />
                1. General terms
                <br />
                These requirements are mandatory when using Api and sending any messages using the Service / Api /
                Website. When sending messages, it is not allowed to advertise goods (works, services) prohibited for
                production and sale in accordance with the law, and it is also prohibited to advertise not registered in
                accordance with the laws of religious organizations and religious schools. The messages shall not be
                used for propaganda or agitation of violent change of the constitutional order, violation of integrity,
                undermining the security of the state, inciting social, racial, national, religious, class and tribal
                hatred, the cult of cruelty and violence, pornography, cynicism, humiliation of human honor and dignity,
                as well as dissemination of information constituting state secrets and other legally protected secrets.
                Spamming and mass mailing are prohibited
                <br />
                2. It is prohibited in the text of the message:
            </p>
            <ul>
                <li>
                    it is prohibited to disseminate information on goods, production, circulation or import of which is
                    prohibited by law;
                </li>
                <li>
                    the text of the communication should not cause panic in society, induce individuals to engage in
                    aggression or other unlawful acts (omissions);
                </li>
                <li>
                    it is prohibited to place statements that are discriminatory on the grounds of a person's origin,
                    social and property status, race, nationality, sex, education, political views, attitude to
                    religion, language, occupation, and place of residence, as well as those that contain a comparison
                    of advertised goods (works, services) with the goods (works, services) of other natural or legal
                    persons, as well as statements, images denigrating their honor, dignity and business reputation;
                </li>
                <li>
                    it is forbidden to provide information or call for actions that may cause a violation of the law,
                    cause or are likely to cause harm to human health or life and/or the environment, or encourage the
                    neglect of safety equipment;
                </li>
                <li>
                    it is forbidden to make statements that discredit, humiliate or ridicule individuals or legal
                    entities that do not use the advertised goods (works, services);
                </li>
                <li>
                    it is prohibited to use or imitate images of the State Emblems, State Flags, the sound of the State
                    Hymns, images of state symbols of any states and international organizations, as well as the
                    official names of state bodies, local governments, except in cases provided for by the laws in the
                    field of intellectual property;
                </li>
                <li>
                    it is forbidden to advertise goods that are subject to mandatory certification or whose production
                    or sale requires a special permit, license, in the absence of an appropriate certificate, permit or
                    license;
                </li>
                <li>
                    it is prohibited to place an image of an individual or use his or her name without the written
                    consent of that individual;
                </li>
                <li>
                    it is forbidden to imitate or copy the brand name, trademark, formulas, images, texts and other
                    commercial designations used in the advertising of other products;
                </li>
                <li>
                    it is prohibited to advertise services related to concert, tour, concert, contest and festival
                    activities without information on the use or non-use of phonograms by performers of musical works;
                </li>
                <li>it is forbidden to distribute advertising for divination and divination services;</li>
                <li>advertising of electronic casinos and internet casinos is prohibited.</li>
            </ul>
            <p>
                3. Licensing: Advertising of goods (works, services) subject to mandatory certification that have not
                passed it is prohibited.
                <br />
                4. Advertising of alcoholic beverages and tobacco products, signs for goods and services, other objects
                of intellectual property rights under which alcoholic beverages and tobacco products are produced:
                <br />
                Advertising of alcoholic beverages, tobacco products, advertising of signs for goods and services, other
                objects of intellectual property rights, under which alcoholic beverages and tobacco products are
                produced, is prohibited. It is also prohibited to advertise in the form of various events, including
                drawings of prizes, lotteries aimed at stimulating demand and interest in alcoholic beverages, tobacco
                and tobacco products.
                <br />
                5. Advertising of medicines, medical equipment, methods of prevention, diagnostics, treatment and
                rehabilitation: advertisement of medical services, methods and means of prevention, diagnostics,
                treatment and medical rehabilitation (hereinafter referred to as services), medicines, medical devices
                and medical equipment, biologically active food additives must be reliable, recognizable without special
                knowledge or application of special means, exclude comparisons with other services, medicines, medical
                devices. It is prohibited:
            </p>
            <ul>
                <li>
                    Advertising of medicines, medical devices and medical equipment, dietary supplements, prophylactics
                    not registered in the countries where the mailing is used;
                </li>
                <li>
                    Advertising of medicines, medical devices and medical equipment in the absence of a license to carry
                    out the relevant type of activity;
                </li>
                <li>Advertising of services in the absence of a license to carry out the relevant type of activity;</li>
                <li>
                    Advertising of services provided by persons who are not licensed to engage in medical activities;
                </li>
                <li>
                    In advertising for the public, the following diseases are mentioned: sexually transmitted diseases,
                    oncological, mental, dangerous infectious diseases, HIV/AIDS, tuberculosis and diabetes mellitus;
                </li>
                <li>
                    to refer in advertising to the recommendations of scientists, health professionals, as well as
                    public officials who, by their own knowledge, may encourage the use and/or prescription of
                    medicines, medical devices and medical equipment;
                </li>
                <li>
                    To present in advertising the service, medicine, medical device and medical equipment, biologically
                    active food additives as unique, the most effective and safe;
                </li>
                <li>
                    claim that the safety and efficacy of the medicinal product is conditioned by its natural origin;
                </li>
                <li>
                    to suggest that the effectiveness of the service provided, treatment with the advertised medicine,
                    dietary supplements is guaranteed, the use of the product is not accompanied by the development of
                    side effects;
                </li>
                <li>
                    Provide information in advertising that is not directly related to the advertised services,
                    medicine, medical device and medical equipment;
                </li>
                <li>
                    Advertising of offers of illegal transactions in respect of tissues (part of tissue) and/or human
                    organs (part of organs).
                </li>
            </ul>
            <p>
                Advertising of medicinal products must contain complete (including appropriate restrictions for the use
                of the medicinal product) and accurate information, the exclusion of which may lead to inappropriate use
                of medicinal products or unjustified risk to the consumer.
                <br />
                6. Advertising of folk medicine (healing) services: <br /> advertisement of healing, as well as
                advertisement of new methods of prevention, diagnostics, rehabilitation and medicines that are under
                consideration in the prescribed manner, but not yet allowed for use. Advertising of folk medicine
                (healing) services and their providers is allowed only with a special permit (license) to practice
                medicine (healing) issued by the relevant authority that implements the state policy in the field of
                health care, and must contain the number, date of issue of the said permit (license) and the name of the
                authority that issued it.
                <br />
                7. Advertising about competitions, lotteries, drawing of prizes, promotional events: <br />
                Must contain information about the date and place of such events and indicate the information source
                from which it is possible to obtain information about the conditions and place of these events.
                Information on any changes in the conditions, place and timing of competitions, lotteries, drawings of
                prizes, promotional events, etc. should be submitted in the order in which it was distributed.
                Instructional and methodical material on the organization and conduct of the lottery must contain the
                following information: type of lottery; lottery organization technology; procedure for formation and
                distribution of the prize fund of the lottery; number of drawings; cost of SMS lottery, SMS-messages;
                name, number and value of the prize fund property; procedure for informing the lottery participants
                about the basic rules of participation in the lottery, receipt of the winnings with the indication of
                the terms and results; procedure and terms of receiving the winnings (including the indication of the
                possibility of Advertising about the discount of prices for products, about sale:
                <br />
                Must contain information about the place, date of beginning and end of the discount of prices for
                products, sale, as well as the ratio of the discount to the previous price of sale of goods. Information
                on prices of goods, tariffs for services given in the advertisement, which is placed or distributed in
                the territory of the country, is indicated only in the national currency.
                <br />
                9. Advertising weapons:
                <br />
                Advertising weapons is not allowed in the service.
                <br />
                10. Advertising services (banking, insurance, investment, etc.):
            </p>
            <ul>
                <li>
                    production, distribution, and placement of advertising of financial (including banking), insurance,
                    investment, and other services associated with the use of money of natural and legal persons, as
                    well as securities, is prohibited:
                </li>
                <li>
                    Provide information in advertising that is not directly related to the advertised services or
                    securities;
                </li>
                <li>guarantee income and dividends on ordinary shares;</li>
                <li>
                    Advertise securities without registration of the issue, as well as in the event of suspension or
                    recognition of the issue of securities as failed;
                </li>
                <li>
                    provide any guarantees or offers of future performance (profitability), including through the
                    announcement of an increase in the price of securities;
                </li>
                <li>conceal any of the essential terms and conditions of the advertising contracts;</li>
                <li>
                    Advertising of types of entrepreneurial activity that encourages individuals to obtain their profit
                    or benefit by involving other individuals in the activities of the enterprise or purchase of goods
                    of the enterprise;
                </li>
                <li>advertising of financial (investment) pyramid activities;</li>
                <li>
                    Banks are prohibited from advertising their activities that do not correspond to reality on the day
                    of its publication;
                </li>
                <li>
                    Legal entities and individuals who do not have a license from the authorized body to conduct banking
                    operations are prohibited from advertising the services provided, which fall under the category of
                    banking operations;
                </li>
                <li>
                    The use of the word "insurance", "reinsurance" by a person who does not have the appropriate license
                    of an authorized body in any language in advertising, or derivative words (expressions) from them
                    that imply the performance of insurance or reinsurance operations in his own name or acting as an
                    insurance broker, actuary, or as an insurance broker, or as an actuary, by a person who does not
                    have the appropriate license of an authorized body in any language;
                </li>
                <li>
                    insurance (reinsurance) organizations are prohibited from advertising their activities that does not
                    correspond to the reality as of the day of its publication.
                </li>
                <li>
                    It is prohibited to act as an advertiser of the services provided by insurance (reinsurance)
                    organizations to the following persons: legal entities that do not have a license of the authorized
                    body by the branches of the insurance of&nbsp;life insurance or&nbsp;general insurance, as well as
                    to&nbsp;carry out reinsurance activities; individuals who are not employees of insurance
                    (reinsurance) organizations and are not authorized by the insurance (reinsurance) organizations;
                </li>
                <li>
                    It is prohibited to act as an advertiser of services provided by professional participants of the
                    securities market to the following persons: Legal entities that do not have a license of an
                    authorized body to carry out professional activities in the securities market; &amp; mdash;
                    individuals who are not employees of licensees and not authorized by licensees.
                </li>
            </ul>
            <p>
                11. Erotic advertising:
                <br />
                Advertising of pornographic materials and prostitution, as well as advertising containing elements of
                cruelty, violence, cynicism and humiliation of human honour and dignity is prohibited.
                <br />
                12. Advertising of the services provided by means of telecommunication:
                <br /> Advertising of the services provided by means of telecommunication, including telephone, when
                distributing it must contain accurate information about:
            </p>
            <ul>
                <li>the content of the advertised service;</li>
                <li>the cost of the advertised service;</li>
                <li>
                    age and other restrictions set by law and by the service provider on the range of consumers of the
                    advertised service;
                </li>
                <li>
                    paid or free use of the telephone channel when providing the advertised service and the cost of one
                    minute of telephone service when receiving the service in the respective region;
                </li>
                <li>full name, name, address of the person providing the service.</li>
            </ul>
            <p>
                13. Advertising of employment services: <br />
                It is forbidden to place information about vacancies for employment, containing the requirements of a
                discriminatory nature in the field of labor.
                <br />
                14. Advertising of construction objects: <br /> Advertising of construction objects for the purpose of
                selling residential and non-residential premises, including those related to the attraction of public
                funds, is permitted only if there is a license (permit) to carry out construction activities and a
                permit to perform construction works at a particular object that is advertised. Such advertising must
                contain the license (permit) number, the date of its issue and the name of the body that issued the
                license (permit). Advertising of residential buildings during the period of suspension of the license of
                the project company for the organization of construction of residential buildings at the expense of
                local executive bodies of the regions (the city of national importance, the capital) at the expense of
                shareholders' money.
                <br />
                15. Advertising and children:
                <br />
                In order to protect minors from abuse of their trust and lack of experience are not allowed:
            </p>
            <ul>
                <li>discrediting the authority of parents and undermining the trust of minors in them;</li>
                <li>A direct offer to encourage parents or others to buy advertised products;</li>
                <li>
                    Direct indication to minors that the possession of a product gives them an advantage over others and
                    that the absence of such a product would be counterproductive;
                </li>
                <li>
                    placement of text, visual or sound information in advertisements showing minors in dangerous places
                    and situations where it is not justified by preventive purposes;
                </li>
                <li>
                    Underestimating the required level of product use skills among minors, except when the results of
                    product use are shown or described. Advertising should provide information on what is realistically
                    achievable for minors of the age group for which the products are intended;
                </li>
                <li>
                    Creation of an unrealistic (distorted) idea among minors about the cost (price) of products for
                    minors, as well as a direct or indirect indication that the advertised products are available for
                    any family budget.
                </li>
            </ul>
        </>
    );
};

export default React.memo(TermsAndConditionsEn);
