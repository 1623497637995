import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import authStore from '~/stores/authStore';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Calculator from './Calculator';
import TablePrice from './TablePrice';
import Status from './Status';
import Profile from './Profile';
import ExpiresAccounts from './ExpiresAccounts';

const useStyles = makeStyles(({ palette, spacing }) =>
    createStyles({
        alignCenter: {
            display: 'flex',
            alignItems: 'center'
        },
        paper: {
            margin: spacing(3, 0),
            padding: spacing(2)
        }
    })
);

const Admin = () => {
    const classes = useStyles();
    const { token, lang } = authStore;

    if (!token) {
        document.location.pathname = '/';
        return null;
    }

    const tabs: { en: { title: string }[]; ru: { title: string }[] } = {
        en: [{ title: 'Status' }, { title: 'Profile' }],
        ru: [{ title: 'Статус' }, { title: 'Профиль' }]
    };

    const [tabId, setTabId] = useState(0);
    const handleChangeTabId = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabId(newValue);
    };

    return (
        <>
            <Container maxWidth="md" disableGutters>
                <Paper variant="outlined" className={classes.paper}>
                    <Tabs
                        value={tabId}
                        onChange={handleChangeTabId}
                        indicatorColor="secondary"
                        textColor="secondary"
                        variant="scrollable"
                    >
                        {tabs[lang].map(({ title }) => (
                            <Tab key={title} label={title} />
                        ))}
                    </Tabs>

                    <Box m={2}>
                        {tabId === 0 && <Status />}
                        {tabId === 1 && <Profile />}
                    </Box>
                </Paper>
                <ExpiresAccounts />
                <Paper variant="outlined" className={classes.paper}>
                    <Calculator />
                </Paper>
                <Paper variant="outlined" className={classes.paper}>
                    <TablePrice />
                </Paper>
            </Container>
        </>
    );
};

export default observer(Admin);
