import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import authStore from '~/stores/authStore';
import { fetchAvailablePhones } from '~/api/accountsApi';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(({ palette, spacing }) =>
    createStyles({
        alignCenter: {
            display: 'flex',
            alignItems: 'center'
        }
    })
);

const langLib = {
    en: {
        apiKey: 'Your Api key',
        availablePhones: 'Available accounts'
    },
    ru: {
        apiKey: 'Ваш Api ключ',
        availablePhones: 'Доступных аккаунтов'
    }
} as const;

const Status = () => {
    const [availableAccounts, setAvailablePhones] = useState<{
        availableWaAccounts: number;
        availableTgAccounts: number;
    }>({ availableTgAccounts: 0, availableWaAccounts: 0 });

    useEffect(() => {
        fetchAvailablePhones().then(phonesNumber => {
            setAvailablePhones(phonesNumber);
        });
    }, []);

    const classes = useStyles();
    const { lang, currentUser } = authStore;
    const lib = langLib[lang];

    return (
        <Grid container spacing={2}>
            <Grid item xs={6} className={classes.alignCenter}>
                <WhatsAppIcon fontSize="large" style={{ color: 'green' }} />
                <Typography variant="h5">Messengers API</Typography>
            </Grid>
            <Grid item xs={6}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="bubble">
                        <span className="bubble-outer-dot">
                            <span className="bubble-inner-dot"></span>
                        </span>
                    </div>
                    <div style={{ marginLeft: '25px' }}>Online!</div>
                </div>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={6} className={classes.alignCenter}>
                <Typography variant="caption">{lib['apiKey']}</Typography>
            </Grid>
            <Grid item xs={6}>
                {currentUser && (
                    <TextField variant="filled" inputProps={{ readOnly: true }} value={currentUser.apiKey} fullWidth />
                )}
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={6} className={classes.alignCenter}>
                <Typography variant="caption">{lib['availablePhones']}</Typography>
            </Grid>
            <Grid item xs={6}>
                <Box display="flex" style={{ gap: '2rem' }}>
                    <Box>
                        Whatsapp: <b style={{ color: '#25D366' }}>{availableAccounts?.availableWaAccounts || 0}</b>
                    </Box>
                    <Box>
                        Telegram: <b style={{ color: '#0088cc' }}>{availableAccounts?.availableTgAccounts || 0}</b>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default observer(Status);
