import config from '../config/server.config';
import authStore from '~/stores/authStore';

export const authHeaders = (): { authorization?: string } => {
    const token = authStore.token;
    if (token) {
        return {
            authorization: 'Bearer ' + token
        };
    }
    return {};
};

const mergeSettings = (settings: any) => {
    settings = settings || {};
    settings = { ...settings, headers: settings.headers || {} };

    settings.headers = { ...settings.headers, ...authHeaders() };

    return settings;
};

const adjustUrl = (url: string): string => {
    return ~url.indexOf('://') ? url : config.serverUrl + url;
};

function queryParams(params: any): string {
    return Object.keys(params)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
}

const makeFetch = async (adjustedUrl: string, settings: object) => {
    try {
        const response = await fetch(adjustedUrl, mergeSettings(settings));
        return adjustResponse(response);
    } catch (error) {
        throw [error].map(({ message }) => message);
    }
};

const get = async <T>(url: string, data = {}, controller?: AbortController): Promise<T> => {
    const settings = {
        method: 'GET',
        type: 'JSON',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        ...(controller && { signal: controller.signal })
    };

    const params = {
        ...data,
        _rand: Math.random()
    };

    let adjustedUrl = adjustUrl(url);
    if (Object.keys(params).length) {
        adjustedUrl += (adjustedUrl.includes('?') ? '&' : '?') + queryParams(params);
    }

    return await makeFetch(adjustedUrl, settings);
};

const loadPlain = async (url: string): Promise<string> => {
    const response = await fetch(url);
    return adjustResponse(response, 'text');
};

const postPlain = async <T>(url: string, body: string): Promise<T> => {
    const settings = {
        method: 'POST',
        type: 'JSON',
        body,
        headers: {
            Accept: 'application/json'
        }
    };

    return await makeFetch(adjustUrl(url), settings);
};

const post = async <T>(url: string, data = {}): Promise<T> => {
    const settings = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    };

    const response = await fetch(adjustUrl(url), mergeSettings(settings));
    return adjustResponse(response);
};

const adjustResponse = async (response: Response, type = 'json'): Promise<any> => {
    const answer = await response.blob();
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        reader.onload = function () {
            if (reader.result) {
                const answer = type === 'json' ? JSON.parse(reader.result.toString()) : reader.result.toString();

                if (response.ok) {
                    resolve(answer);
                } else {
                    reject(answer);
                }
            } else {
                reject();
            }
        };
        reader.readAsText(answer);
    });
};

export default {
    get,
    post,
    postPlain,
    loadPlain
};
