import fetchApi from '~/common/fetchApi';
import { TCurrentUser } from '~/stores/authStore';

export const createAccount = async (data: { email: string; password: string }): Promise<string> => {
    const { token } = await fetchApi.post<{ token: string }>('/auth/signUp', data);
    return token;
};

export const logInRequest = async (data: { email: string; password: string }): Promise<string> => {
    const { token } = await fetchApi.post<{ token: string }>('/auth/logIn', data);
    return token;
};

export const resetPassword = async (data: { email: string }): Promise<string> => {
    const { token } = await fetchApi.post<{ token: string }>('/auth/resetPassword', data);
    return token;
};

export const getCurrentUser = async (): Promise<TCurrentUser> => {
    return await fetchApi.get<TCurrentUser>('/user/currentUser');
};

export const changePass = async (data: { oldPass: string; newPass: string }): Promise<boolean> => {
    return await fetchApi.post<boolean>('/user/changePassword', data);
};
