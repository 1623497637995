import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import authStore from '~/stores/authStore';

const useStyles = makeStyles(({ spacing, palette, breakpoints }) =>
    createStyles({
        root: {
            background: palette.primary.dark
        },
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: spacing(1),
            [breakpoints.down('sm')]: {
                flexDirection: 'column',
                alignItems: 'flex-end'
            }
        },
        text: {
            color: palette.primary.contrastText,
            display: 'flex',
            alignItems: 'center',
            '& > *': {
                marginLeft: spacing(0.5),
                marginRight: spacing(0.5)
            }
        },
        links: {
            flex: 1,
            textAlign: 'left',
            marginLeft: spacing(2)
        },
        link: {
            marginLeft: spacing(2),
            fontSize: '0.75rem',
            color: palette.primary.contrastText
        }
    })
);

const langLib = {
    en: {
        footer: 'API gateway for messengers integration'
    },
    ru: {
        footer: 'API шлюз для интеграции мессенджеров'
    }
} as const;

const Footer = () => {
    const classes = useStyles();
    const year = new Date().getFullYear();

    const { lang } = authStore;
    const lib = langLib[lang];

    return (
        <div className={classes.root}>
            <Container maxWidth="lg">
                <div className={classes.container}>
                    <Typography className={classes.text}>
                        <img src="/static/favicon.png" height={24} width={24} /> {lib['footer']}
                    </Typography>
                    <div className={classes.links}>
                        {lang === 'ru' && (
                            <a className={classes.link} href="/polite">
                                Политика
                            </a>
                        )}
                        {/*{lang === 'ru' && (*/}
                        {/*    <a className={classes.link} href="/terms">*/}
                        {/*        Договор Оферта*/}
                        {/*    </a>*/}
                        {/*)}*/}
                    </div>
                    <div className={classes.text}>
                        {/*<a href="//freekassa.ru/">*/}
                        {/*    <img src="//www.free-kassa.ru/img/fk_btn/14.png" title="Приём оплаты на сайте картами" />*/}
                        {/*</a>*/}
                        <Typography>© connect-api.com 2018-{year}</Typography>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default observer(Footer);
