import fetchApi from '~/common/fetchApi';

type OrderCreate = {
    currency: number;
    price: number;
    type: 1 | 2 | 3; // 1-W, 2-T, 3-TW
    count: number;
    cost: number;
};

export const createOrder = async (data: OrderCreate): Promise<{ redirect_url: string }> => {
    return await fetchApi.post<{ redirect_url: string }>('/order/create', data);
};
