import React, { ChangeEvent, useState } from 'react';
import { observer } from 'mobx-react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import authStore from '~/stores/authStore';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(({ palette, spacing }) =>
    createStyles({
        alignCenter: {
            display: 'flex',
            alignItems: 'center'
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12
        }
    })
);

const langLib = {
    en: {
        changePass: 'Change Password',
        oldPass: 'Old Password',
        newPass: 'New Password',
        cancel: 'Cancel'
    },
    ru: {
        changePass: 'Поменять пароль',
        oldPass: 'Старый пароль',
        newPass: 'Новый пароль',
        cancel: 'Отмена'
    }
} as const;

const ChangePassword = () => {
    const classes = useStyles();
    const { loadingChangePass, errorsChangePass, lang } = authStore;
    const lib = langLib[lang];

    const [showPasswd, setShowPasswd] = useState(false);
    const handleShowPasswd = () => {
        setShowPasswd(!showPasswd);
    };

    const [password, setPassword] = useState('');
    const handlePassword = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value.trim());
    };

    const [newPassword, setNewPassword] = useState('');
    const handleNewPassword = (event: ChangeEvent<HTMLInputElement>) => {
        setNewPassword(event.target.value.trim());
    };

    const checkNewPassword = () => {
        return newPassword.length > 4;
    };

    const handleSaveNewPassword = async () => {
        await authStore.changeNewPassword(password, newPassword);
    };

    return (
        <>
            {!showPasswd && (
                <Button startIcon={<VpnKeyIcon />} color="default" variant="outlined" onClick={handleShowPasswd}>
                    {lib['changePass']}
                </Button>
            )}
            {showPasswd && (
                <Paper variant="outlined">
                    <Box m={3}>
                        {errorsChangePass && <Alert severity="error">{errorsChangePass}</Alert>}
                        <Grid container spacing={3}>
                            <Grid item xs={12} className={classes.alignCenter}>
                                <TextField
                                    size="small"
                                    value={password}
                                    onChange={handlePassword}
                                    type="password"
                                    label={lib['oldPass']}
                                    variant="standard"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.alignCenter}>
                                <TextField
                                    size="small"
                                    value={newPassword}
                                    onChange={handleNewPassword}
                                    type="password"
                                    label={lib['newPass']}
                                    variant="standard"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.alignCenter}>
                                <Button size="small" color="default" variant="outlined" onClick={handleShowPasswd}>
                                    {lib['cancel']}
                                </Button>
                            </Grid>
                            <Grid item xs={6} className={classes.alignCenter}>
                                <Button
                                    disabled={loadingChangePass || !checkNewPassword()}
                                    size="small"
                                    color="secondary"
                                    variant="outlined"
                                    onClick={handleSaveNewPassword}
                                >
                                    {lib['changePass']}
                                </Button>
                                {loadingChangePass && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            )}
        </>
    );
};

export default observer(ChangePassword);
