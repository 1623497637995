import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import SignUpBtn from '~/components/auth/SignUpBtn';
import Button from '@material-ui/core/Button';

import Banner from '~/components/Banner';
import CodeSnippets from './CodeSnippets';
import MainCards from './MainCards';
import MainParallax from './MainParallax';
import { observer } from 'mobx-react';
import authStore from '~/stores/authStore';

const useStyles = makeStyles(({ palette, spacing }) =>
    createStyles({
        text: {
            color: palette.background.paper,
            textShadow: `1px 1px 1px ${palette.primary.dark}`
        }
    })
);

const langLib = {
    en: {
        title1: 'Enterprise-level API for $29/month.',
        title2: 'Receive and send messages using HTTP requests.',
        getStarted: 'Get started'
    },
    ru: {
        title1: 'Профессиональный уровень API от 349 рублей в месяц',
        title2: 'Получение и отправка сообщений через HTTP запросы (Whatsapp и Telegram)',
        getStarted: 'Начать использовать'
    }
} as const;

const Home = () => {
    const classes = useStyles();
    const { lang } = authStore;
    const lib = langLib[lang];

    return (
        <>
            <Banner style={{ height: '400px' }}>
                <Typography variant="h2" component="h1" className={classes.text} align="center">
                    Messengers API
                </Typography>
                <Typography className={classes.text} align="center">
                    {lib['title1']}
                    <br />
                    {lib['title2']}
                </Typography>
                <SignUpBtn>
                    <Button color="primary" variant="contained" size="large">
                        {lib['getStarted']}
                    </Button>
                </SignUpBtn>
            </Banner>
            <MainCards />
            <MainParallax />
            <CodeSnippets />
        </>
    );
};

export default observer(Home);
