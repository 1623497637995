import React, { useState } from 'react';
import { observer } from 'mobx-react';

import authStore from '~/stores/authStore';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';

import TermsAndConditionsEn from './TermsAndConditionsEn';
import TermsAndConditionsRu from './TermsAndConditionsRu';
import { useDocStyles } from '~/components/Docs';

const langLib = {
    en: {
        terms: 'terms and conditions'
    },
    ru: {
        terms: 'условия использования'
    }
} as const;

const TermsAndConditionsModal = () => {
    const { lang } = authStore;
    const lib = langLib[lang];
    const classes = useDocStyles();

    const [openTermsModal, setOpenTermsModal] = useState(false);
    const handleToggleTermsModal = () => {
        setOpenTermsModal(!openTermsModal);
    };

    return (
        <>
            <Link component="button" color="secondary" variant="body2" onClick={handleToggleTermsModal}>
                {lib['terms']}
            </Link>
            {openTermsModal && (
                <Dialog open onClose={handleToggleTermsModal} fullScreen>
                    <DialogTitle>Terms and Conditions | Public agreement | Terms of service</DialogTitle>
                    <DialogContent dividers>
                        <div className={classes.docs}>
                            {lang === 'en' && <TermsAndConditionsEn />}
                            {lang === 'ru' && <TermsAndConditionsEn />}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleToggleTermsModal}>
                            {lang === 'en' ? 'I READ AND ACCEPT' : 'Я прочитал(а) и согласен(на)'}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default observer(TermsAndConditionsModal);
