import { makeObservable, action, observable } from 'mobx';

export type PriceType = 'priceW' | 'priceT' | 'priceWT';

class AdminStore {
    @observable
    phoneNumbers = 0;
    @observable
    priceType: PriceType = 'priceW';
    @observable
    createTime: number | null = null;

    constructor() {
        makeObservable(this);
    }

    @action
    setPhoneNumbers(numbers: number) {
        this.phoneNumbers = numbers;
    }

    @action
    setCreateTime(createTime: number | null) {
        this.createTime = createTime;
    }

    @action
    setPriceType(priceType: PriceType) {
        this.priceType = priceType;
    }
}

export default new AdminStore();
