import React, { ChangeEvent, useState } from 'react';
import { observer } from 'mobx-react';
import validator from 'validator';
import { useHistory } from 'react-router-dom';
import authStore from '~/stores/authStore';

import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import CountryCode from './CountryCode';

import TermsAndConditionsModal from './TermsAndConditionsModal';
import { authMobileStyle, AuthModals } from './AuthModal';
import AlreadyHaveAnAccount from '~/components/auth/AlreadyHaveAnAccount';

const langLib = {
    en: {
        password: 'Password *',
        passwordIsInvalid: 'Password is required (min 5 chars)',
        emailIsInvalid: 'Email is required',
        confirm: 'Password (Confirm) *',
        confirmIsRequired: 'Password (Confirm) is required',
        accept: 'I read and accept',
        createAccount: 'Create My Account',
        alreadyHaveAccount: 'Already have an account?',
        logIn: 'Log in'
    },
    ru: {
        password: 'Пароль *',
        passwordIsInvalid: 'Пароль должен состоять минимум из 5 символов',
        emailIsInvalid: 'Email обязателен',
        confirm: 'Пароль (Повторно) *',
        confirmIsRequired: 'Пароли не совпадают',
        accept: 'Я прочитал(а) и согласен',
        createAccount: 'Создать Аккаунт',
        alreadyHaveAccount: 'У вас уже есть аккаунт?',
        logIn: 'Войти'
    }
} as const;

const SignUpModal = ({ changeModalType }: { changeModalType: (type: AuthModals) => void }) => {
    const classes = authMobileStyle();
    const history = useHistory();

    const { lang } = authStore;
    const lib = langLib[lang];

    const [email, setEmail] = useState('');
    const handleEmail = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value.trim());
        setEmailIsInvalid(false);
    };
    const [isEmailInvalid, setEmailIsInvalid] = useState(false);
    const checkEmail = () => {
        const valid = validator.isEmail(email);
        setEmailIsInvalid(!valid);
        return valid;
    };

    const [password, setPassword] = useState('');
    const handlePassword = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value.trim());
        setPasswordIsInvalid(false);
        // if (!isConfirmInvalid) {
        //     checkConfirm();
        // }
    };
    const [isPasswordInvalid, setPasswordIsInvalid] = useState(false);
    const checkPassword = () => {
        const valid = password.length > 4;
        setPasswordIsInvalid(!valid);
        return valid;
    };

    const [confirm, setConfirm] = useState('');
    const handleConfirm = (event: ChangeEvent<HTMLInputElement>) => {
        setConfirm(event.target.value.trim());
        setConfirmIsInvalid(false);
    };
    const [isConfirmInvalid, setConfirmIsInvalid] = useState(false);
    const checkConfirm = () => {
        const valid = password === confirm;
        setConfirmIsInvalid(!valid);
        return valid;
    };

    const [readTerms, setReadTerms] = useState(false);
    const toggleReadTerms = () => {
        setReadTerms(!readTerms);
    };

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSendRequest = async () => {
        if (checkEmail() && checkPassword() && checkConfirm()) {
            setIsLoading(true);
            setError('');
            try {
                await authStore.signUp(email, password);
            } catch (error) {
                setError(error?.message || error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <DialogContent dividers>
            {error && <Alert severity="error">{error}</Alert>}

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <CountryCode />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={email}
                        onChange={handleEmail}
                        onBlur={checkEmail}
                        type="email"
                        error={isEmailInvalid}
                        helperText={isEmailInvalid ? lib['emailIsInvalid'] : ''}
                        label="Email *"
                        fullWidth
                        variant="standard"
                        autoComplete="off"
                        disabled={isLoading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={password}
                        onChange={handlePassword}
                        onBlur={checkPassword}
                        type="password"
                        label={lib['password']}
                        error={isPasswordInvalid}
                        helperText={isPasswordInvalid ? lib['passwordIsInvalid'] : ''}
                        fullWidth
                        variant="standard"
                        autoComplete="off"
                        disabled={isLoading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={confirm}
                        onChange={handleConfirm}
                        onBlur={checkConfirm}
                        type="password"
                        label={lib['confirm']}
                        error={isConfirmInvalid}
                        helperText={isConfirmInvalid ? lib['confirmIsRequired'] : ''}
                        fullWidth
                        variant="standard"
                        autoComplete="off"
                        disabled={isLoading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DialogContentText className={classes.root}>
                        <Checkbox onChange={toggleReadTerms} checked={readTerms} disabled={isLoading} />
                        <Typography variant="body2" component="span">
                            {lib['accept']}
                        </Typography>
                        <TermsAndConditionsModal />
                    </DialogContentText>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.wrapper}>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={!readTerms || isLoading}
                            fullWidth
                            onClick={handleSendRequest}
                        >
                            {lib['createAccount']}
                        </Button>
                        {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </Grid>
                <AlreadyHaveAnAccount changeModalType={changeModalType} />
            </Grid>
        </DialogContent>
    );
};

export default observer(SignUpModal);
