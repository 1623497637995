import React from 'react';
import ReactDOM from 'react-dom';
import { configure } from 'mobx';

import authStore from '~/stores/authStore';
import adminStore from '~/stores/adminStore';

export const GlobalStore = {
    adminStore,
    authStore
};

configure({ enforceActions: 'never' });

import App from './App';

const $root = document.getElementById('root');

if ($root) {
    ReactDOM.render(<App />, $root);
}
