import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import Home from './components/Home';
import Admin from './components/Admin';
import Docs from './components/Docs';
import Sdk from './components/Sdk';
import Support from './components/Support';
import Price from './components/Price';
import Polite from './components/Polite';
import Terms from './components/Terms';

import TopMenu from '~/components/TopMenu';

const MainRouterApp = () => {
    return (
        <BrowserRouter>
            <TopMenu />
            <main>
                <Switch>
                    <Route path="/price" component={Price} />
                    <Route path="/admin" component={Admin} />
                    <Route path="/api" component={Docs} />
                    <Route path="/sdk" component={Sdk} />
                    <Route path="/support" component={Support} />
                    <Route path="/polite" component={Polite} />
                    <Route path="/terms" component={Terms} />
                    <Route path="/" component={Home} />
                </Switch>
            </main>
        </BrowserRouter>
    );
};

export default MainRouterApp;
