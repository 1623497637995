import fetchApi from '~/common/fetchApi';

export const fetchAvailablePhones = async (): Promise<{
    availableWaAccounts: number;
    availableTgAccounts: number;
}> => {
    return await fetchApi.get<{
        availableWaAccounts: number;
        availableTgAccounts: number;
    }>('/account/availablePhones');
};

export type ExpireAccount = {
    order_id: number;
    type: 1 | 2 | 3;
    count: number;
    createTime: number;
    expireTime: number;
};

export const fetchExpiresAccounts = async (): Promise<ExpireAccount[]> => {
    return await fetchApi.get<ExpireAccount[]>('/account/expires');
};
