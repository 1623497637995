import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Banner from '~/components/Banner';
import { observer } from 'mobx-react';
import authStore from '~/stores/authStore';
import TablePrice from '~/components/Admin/TablePrice';

const useStyles = makeStyles(({ palette, spacing }) =>
    createStyles({
        text: {
            color: palette.background.paper,
            textShadow: `1px 1px 1px ${palette.primary.dark}`
        },
        root: {
            margin: spacing(1, 0),
            display: 'flex',
            flexDirection: 'column',
            '& > *': {
                marginTop: spacing(3)
            },
            '& li': {
                margin: spacing(1, 0)
            }
        }
    })
);

const langLib = {
    en: {
        header: 'Price',
        contactUs: 'Select your feet pit price',
        title: 'We’re all ears. Price (30 days), $'
    },
    ru: {
        header: 'Тарифы',
        contactUs: 'Стоимость',
        title: 'Выберите подходящий для вас тариф, за номер (30 дней), руб.'
    }
} as const;

const SDK = () => {
    const { lang } = authStore;
    const lib = langLib[lang];
    const classes = useStyles();

    return (
        <>
            <Banner style={{ height: '160px' }}>
                <Typography variant="h3" component="h1" className={classes.text} align="center">
                    {lib['header']}
                </Typography>
            </Banner>
            <Container maxWidth="md">
                <div className={classes.root}>
                    <Typography variant="h4">{lib['contactUs']}</Typography>
                    <Typography variant="h5">{lib['title']}</Typography>
                    <TablePrice extended />
                </div>
            </Container>
        </>
    );
};

export default observer(SDK);
