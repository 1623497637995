import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import TermsAndConditionsRu from '~/components/auth/TermsAndConditionsRu';
import { useDocStyles } from '~/components/Docs';

const Polite = () => {
    const classes = useDocStyles();

    return (
        <Container maxWidth="md" className={classes.docs}>
            <TermsAndConditionsRu />
        </Container>
    );
};

export default Polite;
