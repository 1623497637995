import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import authStore from '~/stores/authStore';
import { ExpireAccount, fetchExpiresAccounts } from '~/api/accountsApi';

import RenewButton from './RenewButton';

const langLib = {
    en: {
        count: 'Accounts',
        createTime: 'Create Time',
        expireTime: 'Expire Time',
        availableAccounts: 'Available Accounts'
    },
    ru: {
        count: 'Кол-во аккаунтов',
        createTime: 'Создание',
        expireTime: 'Годен до',
        availableAccounts: 'Оплаченные аккаунты'
    }
} as const;

const useStyles = makeStyles(({ palette, spacing }) =>
    createStyles({
        paper: {
            margin: spacing(3, 0),
            padding: spacing(2)
        }
    })
);

const Status = () => {
    const classes = useStyles();

    const [expiresAccounts, setExpiresAccounts] = useState<ExpireAccount[]>([]);

    useEffect(() => {
        fetchExpiresAccounts().then(accounts => setExpiresAccounts(accounts));
    }, []);

    const { lang, currentUser } = authStore;
    const lib = langLib[lang];

    if (!expiresAccounts.length) {
        return null;
    }

    return (
        <Paper variant="outlined" className={classes.paper}>
            <Typography variant="h6" color="secondary">
                {lib['availableAccounts']}
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center" />
                        <TableCell align="center">{lib['count']}</TableCell>
                        <Hidden xsDown>
                            <TableCell align="right">{lib['createTime']}</TableCell>
                        </Hidden>
                        <TableCell align="right">{lib['expireTime']}</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {expiresAccounts.map(({ order_id, count, expireTime, type, createTime }) => {
                        const createDate = new Date(createTime * 1000);
                        const expireDate = new Date(expireTime * 1000);

                        return (
                            <TableRow key={order_id}>
                                {/*<TableCell align="center" style={{ color: '#25D366' }}>{type === 1 ? 'Whatsapp' : 'Telegram'}</TableCell>*/}
                                {/*<TableCell align="center" style={{ color: '#25D366' }}>{type === 1 ? 'Whatsapp' : 'Telegram'}</TableCell>*/}
                                {type === 1 && (
                                    <TableCell align="center" style={{ color: '#25D366' }}>
                                        Whatsapp
                                    </TableCell>
                                )}
                                {type === 2 && (
                                    <TableCell align="center" style={{ color: '#0088cc' }}>
                                        Telegram
                                    </TableCell>
                                )}
                                {type === 3 && (
                                    <TableCell align="center" style={{ color: 'red' }}>
                                        Whatsapp + Telegram
                                    </TableCell>
                                )}
                                <TableCell align="center">{count}</TableCell>
                                <Hidden xsDown>
                                    <TableCell align="right">{createDate.toLocaleDateString()}</TableCell>
                                </Hidden>
                                <TableCell align="right">{expireDate.toLocaleDateString()}</TableCell>
                                <TableCell align="right">
                                    <RenewButton
                                        count={count}
                                        createTime={createTime}
                                        priceType={type === 1 ? 'priceW' : type === 2 ? 'priceT' : 'priceWT'}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Paper>
    );
};

export default observer(Status);
