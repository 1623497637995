import React, { ReactNode, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import AuthModal, { AuthModals } from './AuthModal';
import authStore from '~/stores/authStore';
import Button from '@material-ui/core/Button';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const LogInBtn = ({ children }: { children: ReactNode }) => {
    const history = useHistory();

    const [open, setOpen] = useState(false);
    const handleToggleOpen = () => {
        setOpen(!open);
    };

    const { currentUser } = authStore;
    if (currentUser) {
        return (
            <Button
                onClick={() => history.push('/admin')}
                color="secondary"
                variant="contained"
                size="small"
                startIcon={<AccountCircleIcon />}
            >
                {currentUser.email}
            </Button>
        );
    }

    return (
        <>
            <span onClick={handleToggleOpen}>{children}</span>
            {open && <AuthModal type={AuthModals.logIn} onClose={handleToggleOpen} />}
        </>
    );
};

export default observer(LogInBtn);
