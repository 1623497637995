import React from 'react';
import { observer } from 'mobx-react';

import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { authMobileStyle, AuthModals } from './AuthModal';
import authStore from '~/stores/authStore';

const langLib = {
    en: {
        alreadyHaveAccount: 'Already have an account?',
        logIn: 'Log in'
    },
    ru: {
        alreadyHaveAccount: 'У вас уже есть аккаунт?',
        logIn: 'Войти'
    }
} as const;

const AlreadyHaveAnAccount = ({ changeModalType }: { changeModalType: (type: AuthModals) => void }) => {
    const classes = authMobileStyle();

    const { lang } = authStore;
    const lib = langLib[lang];

    return (
        <Grid item xs={12}>
            <DialogContentText className={classes.root}>
                <Typography variant="body2" component="span">
                    {lib['alreadyHaveAccount']}
                </Typography>
                <Link
                    component="button"
                    color="secondary"
                    variant="body2"
                    onClick={() => changeModalType(AuthModals.logIn)}
                >
                    {lib['logIn']}
                </Link>
            </DialogContentText>
        </Grid>
    );
};

export default observer(AlreadyHaveAnAccount);
