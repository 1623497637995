const codes = [
    {
        title: 'php',
        language: 'php',
        code: `
// URL for request GET /messages
$token = '83763g87x';
$instanceId = '777';
$url = 'https://api.connect-api.com/instance'.$instanceId.'/messages?token='.$token;
$result = file_get_contents($url); // Send a request
$data = json_decode($result, 1); // Parse JSON
foreach($data['messages'] as $message){ // Echo every message
    echo "Sender:".$message['author']."<br>";
    echo "Message: ".$message['body']."<br>";
}
`
    },
    {
        title: 'node.js',
        language: 'javascript',
        code: `
var request = require('request');// npm install request
var token = '83763g87x';
var instanceId = '777';
var url = \`https://api.connect-api.com/instance\${instanceId}/messages?token=\${token}\`;
request(url, function (error, response, body) { // Make a GET request
    if (error) return console.error('HTTP Error', error);
    // Parse a response
    var data = JSON.parse(body);
    // For each message
    for (var i = 0; i < data.messages.length; i++) {
        var message = data.messages[i];
        console.log(message.author + ': ' + message.body); //Send it to console
    }
});
`
    },
    {
        title: 'jquery',
        language: 'javascript',
        code: `
var token = '83763g87x';
var instanceId = '777';
var url = \`https://api.connect-api.com/instance\${instanceId}/messages?token=\${token}\`;
$.get(url, function (data) { // Make a GET request
    for (var i = 0; i < data.messages.length; i++) { // For each message
        var message = data.messages[i];
        console.log(message.author + ': ' + message.body); //Send it to console
    }
});
`
    },
    {
        title: 'curl (bash)',
        language: 'bash',
        code: `
# Just a GET request
curl "https://api.connect-api.com/instance777/messages?token=83763g87x"
`
    }
];

export default codes;
