import React, { useEffect } from 'react';
import { createMuiTheme, createStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import muiThemeConfig from './muiTheme';

import Footer from '~/components/Footer';
import CssBaseline from '@material-ui/core/CssBaseline';

import MainRouter from './mainRouter';

import authStore from '~/stores/authStore';

const outerTheme = createMuiTheme(muiThemeConfig);

const useStyles = makeStyles(({ spacing, palette }) =>
    createStyles({
        root: {
            display: 'flex',
            minHeight: '100vh',
            flexDirection: 'column',
            justifyContent: 'space-between'
        }
    })
);

const App = () => {
    const classes = useStyles();

    useEffect(() => {
        authStore.pullCurrentUser();
    }, []);

    return (
        <ThemeProvider theme={outerTheme}>
            <CssBaseline />
            <div className={classes.root}>
                <div>
                    <MainRouter />
                </div>
                <Footer />
            </div>
        </ThemeProvider>
    );
};

export default App;
