import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { createOrder } from '~/api/orderApi';

import authStore from '~/stores/authStore';
import adminStore from '~/stores/adminStore';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import PaymentIcon from '@material-ui/icons/Payment';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { matchPrice } from '~/components/Admin/Calculator';
import Alert from '@material-ui/lab/Alert';

const styles = makeStyles(({ spacing, palette }) =>
    createStyles({
        closeButton: {
            position: 'absolute',
            right: spacing(1),
            top: spacing(1),
            color: palette.grey[500]
        },
        titleHeader: {
            display: 'flex',
            alignItems: 'center'
        },
        paymentLogos: {
            background: `url('/static/cards.webp')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPositionY: 'center',
            backgroundPositionX: 'center',
            height: 90
        },
        bill: {
            margin: spacing(1, 0),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& > *': {
                marginTop: spacing(2)
            }
        }
    })
);

const langLib = {
    en: {
        order: 'Order',
        subscribeVia: 'Subscribe via Visa or Mastercard',
        details: 'Details',
        subscribe: 'Subscribe',
        renew: 'Renew',
        total: 'Total',
        qty: 'Qty.',
        price: 'Price',
        sum: 'Sum',
        desc: 'Desc',
        days: 'for 30 days',

        priceW: 'WhatsApp Accounts',
        priceT: 'Telegram Accounts',
        priceWT: 'WhApp+Tg Accounts'
    },
    ru: {
        order: 'Заказ',
        subscribeVia: 'Оплата подписки через Мир, Visa, Mastercard',
        details: 'Детали',
        subscribe: 'Оформить подписку',
        renew: 'Продлить',
        total: 'Итого',
        qty: 'К-во',
        price: 'Цена',
        sum: 'Сумма',
        desc: 'Товар',
        days: 'на 30 дней',

        priceW: 'WhatsApp Accounts',
        priceT: 'Telegram Accounts',
        priceWT: 'WhApp+Tg Accounts'
    }
} as const;

const OrderModal = ({ onClose }: { onClose: () => void }) => {
    const { lang } = authStore;
    const lib = langLib[lang];

    const classes = styles();
    const { phoneNumbers, createTime, priceType } = adminStore;

    const price = matchPrice(lang, priceType, phoneNumbers);
    const cost = matchPrice(lang, priceType, phoneNumbers) * phoneNumbers;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleCreateOrder = async () => {
        setLoading(true);
        setError('');
        try {
            const { redirect_url } = await createOrder({
                price,
                type: priceType === 'priceW' ? 1 : priceType === 'priceT' ? 2 : 3,
                cost,
                count: phoneNumbers,
                currency: lang === 'ru' ? 2 : 1
            });
            document.location.href = redirect_url;
        } catch (error) {
            setLoading(false);
            setError(error?.message || error);
        }
    };

    return (
        <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle disableTypography>
                <Typography variant="h6" className={classes.titleHeader}>
                    {lib['order']}
                </Typography>
                <IconButton className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers>
                {error && <Alert severity="error">{error}</Alert>}

                <Typography variant="subtitle2" align="center">
                    {lib['subscribeVia']}
                </Typography>

                <div className={classes.paymentLogos}></div>

                {/*<form id="pay_form" action="https://auth.robokassa.ru/Merchant/Index.aspx" method="POST">*/}
                {/*    <input type="hidden" name="MerchantLogin" value={mrh_login} />*/}
                {/*    <input type="hidden" name="OutSum" value={out_summ} />*/}
                {/*    <input type="hidden" name="InvId" value={inv_id} />*/}
                {/*    <input type="hidden" name="Description" value={inv_desc} />*/}
                {/*    <input type="hidden" name="SignatureValue" value={crc} />*/}
                {/*    <input type="hidden" name="Shp_item" value={shp_item} />*/}
                {/*    /!*<input type="hidden" name="IncCurrLabel" value={ФФФ} />*!/*/}
                {/*    <input type="hidden" name="Culture" value={culture} />*/}
                {/*    <input type="hidden" name="Email" value={currentUser?.email} />*/}
                {/*    <input type="hidden" name="OutSumCurrency" value={OutSumCurrency} />*/}
                {/*    <input type="hidden" name="ExpirationDate" value={ExpirationDate} />*/}
                {/*    /!*<input type="hidden" name="IsTest" value="1" />*!/*/}
                {/*    <input type="submit" value="Pay" />*/}
                {/*</form>*/}

                <div className={classes.bill}>
                    <div>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" colSpan={3}>
                                        {lib['details']}
                                    </TableCell>
                                    <TableCell align="right">{lib['price']}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{lib['desc']}</TableCell>
                                    <TableCell align="right">{lib['qty']}</TableCell>
                                    <TableCell align="right">{lib['price']}</TableCell>
                                    <TableCell align="right">{lib['sum']}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        {lib[priceType]} {lib['days']}
                                    </TableCell>
                                    <TableCell align="right">{phoneNumbers}</TableCell>
                                    <TableCell align="right">{authStore.printCost(price)}</TableCell>
                                    <TableCell align="right">{authStore.printCost(cost)}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell colSpan={3}>
                                        <b>{lib['total']}</b>
                                    </TableCell>
                                    <TableCell align="right">{authStore.printCost(cost)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>

                    <Button
                        disabled={loading}
                        onClick={handleCreateOrder}
                        startIcon={<PaymentIcon />}
                        color="secondary"
                        variant="outlined"
                    >
                        {lib[createTime ? 'renew' : 'subscribe']}
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default observer(OrderModal);
