import React, { ReactNode, CSSProperties } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

const animationStyle = `
@-webkit-keyframes Gradient {
    0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
}

@-moz-keyframes Gradient {
    0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
}

@keyframes Gradient {
    0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
}
`;

const useStyles = makeStyles(({ palette, spacing }) =>
    createStyles({
        root: {
            width: '100wh',
            color: '#fff',
            background: `linear-gradient(-45deg, ${palette.primary.main}, ${palette.secondary.main}, ${palette.primary.contrastText}, #4ced69)`,
            backgroundSize: '400% 400%',
            animation: 'Gradient 15s ease infinite',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            flexDirection: 'column',
            padding: spacing(10)
        }
    })
);

const Banner = ({ children, style }: { children: ReactNode; style?: CSSProperties }) => {
    const classes = useStyles();

    return (
        <>
            <style>{animationStyle}</style>
            <div className={classes.root} style={style}>
                {children}
            </div>
        </>
    );
};

export default observer(Banner);
