import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';

import authStore from '~/stores/authStore';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';

import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import LogInBtn from './auth/LogInBtn';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { countryToFlag } from '~/components/auth/CountryCode';

const useStyles = makeStyles(({ spacing, palette, breakpoints, shape }) =>
    createStyles({
        grow: {
            flexGrow: 1
        },
        topMenu: {
            position: 'relative',
            borderRadius: shape.borderRadius,
            marginRight: spacing(2),
            marginLeft: 0,
            width: '0',
            display: 'none',
            [breakpoints.up('md')]: {
                marginLeft: spacing(3),
                width: 'auto',
                display: 'block'
            },
            '& a': {
                textDecoration: 'none'
            }
        },
        sectionDesktop: {
            display: 'none',
            [breakpoints.up('md')]: {
                display: 'flex',
                alignItems: 'center',
                '& > *': {
                    marginLeft: spacing(2)
                }
            }
        },
        sectionMobile: {
            display: 'flex',
            [breakpoints.up('md')]: {
                display: 'none'
            }
        },
        topLogo: {
            background: `url('/static/logo.png')`,
            backgroundPosition: 'center',
            height: '85px',
            width: '200px'
        },
        drawerPaper: {
            width: 250,
            background: palette.primary.light
        },
        menuItem: {
            '& > a': {
                color: palette.primary.contrastText,
                textDecoration: 'none',
                width: '100%'
            }
        }
    })
);

const langLib = {
    en: {
        home: 'Home',
        api: 'Messengers Api',
        sdk: 'SDK',
        support: 'Support',
        logIn: 'Log In',
        price: 'Price'
    },
    ru: {
        home: 'Главная',
        api: 'Messengers Api',
        sdk: 'SDK',
        support: 'Поддержка',
        logIn: 'Войти',
        price: 'Стоимость'
    }
} as const;

const TopMenu = () => {
    const classes = useStyles();
    const location = useLocation();
    const { lang } = authStore;
    const lib = langLib[lang];

    const [isMenuOpen, setMenuOpen] = useState(false);
    const toggleMenuOpen = () => {
        setMenuOpen(!isMenuOpen);
    };

    const { pathname } = location;

    return (
        <>
            <div className={classes.grow}>
                <AppBar position="static">
                    <Toolbar>
                        <Link to="/">
                            <div className={classes.topLogo} />
                        </Link>
                        <div className={classes.topMenu}>
                            <Link to="/">
                                <Button variant="contained" color={pathname === '/' ? 'secondary' : 'primary'}>
                                    {lib['home']}
                                </Button>
                            </Link>
                            <Link to="/price">
                                <Button
                                    variant="contained"
                                    color={pathname.includes('/price') ? 'secondary' : 'primary'}
                                >
                                    {lib['price']}
                                </Button>
                            </Link>
                            <Link to="/api">
                                <Button variant="contained" color={pathname.includes('/api') ? 'secondary' : 'primary'}>
                                    {lib['api']}
                                </Button>
                            </Link>
                            <Link to="/sdk">
                                <Button variant="contained" color={pathname.includes('/sdk') ? 'secondary' : 'primary'}>
                                    {lib['sdk']}
                                </Button>
                            </Link>
                            <Link to="/support">
                                <Button
                                    variant="contained"
                                    color={pathname.includes('/support') ? 'secondary' : 'primary'}
                                >
                                    {lib['support']}
                                </Button>
                            </Link>
                        </div>
                        <div className={classes.grow} />
                        <div className={classes.sectionDesktop}>
                            <Tooltip title={lang === 'en' ? 'Сменить язык на русский' : 'Change to English'}>
                                <IconButton color="inherit" onClick={authStore.toggleLanguage}>
                                    {countryToFlag(lang === 'en' ? 'RU' : 'GB')}
                                </IconButton>
                            </Tooltip>

                            <LogInBtn>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    size="small"
                                    startIcon={<ExitToAppIcon />}
                                >
                                    {lib['logIn']}
                                </Button>
                            </LogInBtn>
                        </div>
                        <div className={classes.sectionMobile}>
                            <IconButton onClick={toggleMenuOpen} color="inherit">
                                <MenuIcon />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
            </div>
            <Drawer
                anchor="right"
                classes={{
                    paper: classes.drawerPaper
                }}
                open={isMenuOpen}
                onClose={toggleMenuOpen}
            >
                <MenuList>
                    <MenuItem className={classes.menuItem} onClick={toggleMenuOpen}>
                        <Link to="/">{lib['home']}</Link>
                    </MenuItem>
                    <MenuItem className={classes.menuItem} onClick={toggleMenuOpen}>
                        <Link to="/price">{lib['price']}</Link>
                    </MenuItem>
                    <MenuItem className={classes.menuItem} onClick={toggleMenuOpen}>
                        <Link to="/api">{lib['api']}</Link>
                    </MenuItem>
                    <MenuItem className={classes.menuItem} onClick={toggleMenuOpen}>
                        <Link to="/sdk">{lib['sdk']}</Link>
                    </MenuItem>
                    <MenuItem className={classes.menuItem} onClick={toggleMenuOpen}>
                        <Link to="/support">{lib['support']}</Link>
                    </MenuItem>
                </MenuList>

                <LogInBtn>
                    <Button color="secondary" variant="contained" size="small" startIcon={<ExitToAppIcon />} fullWidth>
                        {lib['logIn']}
                    </Button>
                </LogInBtn>

                <br />
                <Tooltip title={lang === 'en' ? 'Сменить язык на русский' : 'Change to English'}>
                    <IconButton color="inherit" onClick={authStore.toggleLanguage}>
                        {countryToFlag(lang === 'en' ? 'RU' : 'GB')}
                    </IconButton>
                </Tooltip>
            </Drawer>
        </>
    );
};

export default observer(TopMenu);
