import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Banner from '~/components/Banner';
import { observer } from 'mobx-react';
import authStore from '~/stores/authStore';

const useStyles = makeStyles(({ palette, spacing }) =>
    createStyles({
        text: {
            color: palette.background.paper,
            textShadow: `1px 1px 1px ${palette.primary.dark}`
        },
        root: {
            margin: spacing(1, 0),
            display: 'flex',
            flexDirection: 'column',
            '& > *': {
                marginTop: spacing(3)
            },
            '& li': {
                margin: spacing(1, 0)
            }
        }
    })
);

const langLib = {
    en: {
        support: 'Support',
        contactUs: 'Contact Us',
        title: 'We’re all ears.',
        choose: 'Please choose a topic below related to your inquiry:'
    },
    ru: {
        support: 'Техподдержка',
        contactUs: 'Связь с нами',
        title: 'Прислушаемся ко всему',
        choose: 'Выберите удобный способ связи из представленных тут:'
    }
} as const;

const SDK = () => {
    const { lang } = authStore;
    const lib = langLib[lang];
    const classes = useStyles();

    return (
        <>
            <Banner style={{ height: '160px' }}>
                <Typography variant="h3" component="h1" className={classes.text} align="center">
                    {lib['support']}
                </Typography>
            </Banner>
            <Container maxWidth="sm">
                <div className={classes.root}>
                    <Typography variant="h4">{lib['contactUs']}</Typography>
                    <Typography variant="h5">{lib['title']}</Typography>
                    <Typography variant="body1">{lib['choose']}</Typography>
                    <List disablePadding>
                        <ListItem>
                            <ListItemText
                                primary={
                                    <a href="https://t.me/connect_api" target="_blank">
                                        @connect_api
                                    </a>
                                }
                                secondary="Telegram"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={<a href="mailto:info@connect-api.com">info@connect-api.com</a>}
                                secondary="Email"
                            />
                        </ListItem>
                    </List>
                </div>
            </Container>
        </>
    );
};

export default observer(SDK);
