import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Parallax } from 'react-parallax';
import authStore from '~/stores/authStore';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import SignUpBtn from '~/components/auth/SignUpBtn';

const useStyles = makeStyles(({ spacing, palette }) =>
    createStyles({
        root: {
            height: 400,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            flexDirection: 'column',
            padding: spacing(10),
            '& > *': {
                margin: spacing(1, 0)
            }
        },
        text: {
            color: palette.primary.contrastText
        }
    })
);

const langLib = {
    en: {
        signUpNow: 'Sign Up Now',
        priceTitle: 'PRICE AND SIGN UP'
    },
    ru: {
        signUpNow: 'Зарегистрироваться',
        priceTitle: 'Цены и Регистрация'
    }
} as const;

const Container = () => {
    const { lang } = authStore;
    const lib = langLib[lang];
    const classes = useStyles();

    return (
        <Parallax blur={2} bgImage="/static/parallax.jpg" bgImageAlt="the cat" strength={1000}>
            <div className={classes.root}>
                <Typography variant="h4" className={classes.text} align="center">
                    {lib['priceTitle']}
                </Typography>
                {lang === 'en' && (
                    <Typography className={classes.text} align="center">
                        Free for 3 days, then $39/month or $390/year for a WhatsApp account. <br />
                        The price includes technical support and 6000+ messages per day.
                    </Typography>
                )}
                {lang === 'ru' && (
                    <Typography className={classes.text} align="center">
                        От 399 рублей в месяц за аккаунт. <br />
                        Тариф включает обработку 6000+ сообщений в день с одного аккаунта
                    </Typography>
                )}

                <SignUpBtn>
                    <Button color="secondary" variant="contained" size="large">
                        {lib['signUpNow']}
                    </Button>
                </SignUpBtn>
            </div>
        </Parallax>
    );
};

export default observer(Container);
