import React from 'react';
import Banner from '~/components/Banner';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { gruvboxDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import SyntaxHighlighter from 'react-syntax-highlighter';
import authStore from '~/stores/authStore';

export const useDocStyles = makeStyles(({ palette, spacing }) =>
    createStyles({
        text: {
            color: palette.background.paper,
            textShadow: `1px 1px 1px ${palette.primary.dark}`
        },
        docs: {
            '& > *': {
                margin: spacing(3, 0)
            },
            '& table': {
                borderCollapse: 'collapse'
            },
            '& td': {
                border: '1px solid #ccc',
                padding: spacing(1)
            },
            '& h3': {
                fontWeight: 500
            },
            '& h5': {
                fontWeight: 500,
                fontSize: '1em'
            },
            '& b': {
                fontWeight: 500
            },
            '& a': {
                marginLeft: spacing(0.5),
                marginRight: spacing(0.5)
            }
        }
    })
);

const ApiDocs = () => {
    const classes = useDocStyles();
    const { lang } = authStore;

    return (
        <>
            <Banner style={{ height: '160px' }}>
                <Typography variant="h3" component="h1" className={classes.text} align="center">
                    {lang === 'en' ? 'Messengers API documentation' : 'Messengers API документация'}
                </Typography>
            </Banner>
            <Container maxWidth="md" className={classes.docs}>
                <p>The REST API allows you to receive and send messages through your WhatsApp account. Sign up now</p>
                <p>
                    Parameters in GET queries pass query string. Parameters in POST requests — through the JSON-encoded
                    request body. The authorization token is always passed to query string (?token=xxxxxx).
                </p>
                <p>
                    The WhatsApp API is based on the WhatsApp WEB protocol and excludes the ban both when using
                    libraries from mgp25 and the like. Despite this, your account can be banned by anti-spam system
                    WhatsApp after several clicking the "block" button.
                </p>
                <p>
                    <b>Guides for building a Whatsapp bot</b> on{' '}
                    <a href="https://www.php.net/" target="_blank">
                        PHP
                    </a>{' '}
                    , on{' '}
                    <a href="https://www.php.net/" target="_blank">
                        Python
                    </a>
                    , on{' '}
                    <a href="https://www.php.net/" target="_blank">
                        Node.JS
                    </a>
                    , on{' '}
                    <a href="https://www.php.net/" target="_blank">
                        C#
                    </a>{' '}
                    or on{' '}
                    <a href="https://www.php.net/" target="_blank">
                        Java
                    </a>
                    . Bot in conjunction with Google Sheets.
                </p>

                <h3>GET /status</h3>

                <p>
                    Get the account status and QR code for authorization. Reauthorization is necessary only in case of
                    changing the device or manually pressing "Logout on all devices" on the phone. Keep the WhastsApp
                    application open during authorization.
                </p>

                <table>
                    <tbody>
                        <tr>
                            <td>init</td>
                            <td>Initial status</td>
                        </tr>
                        <tr>
                            <td>loading</td>
                            <td>The system is still loading, try again in 1 minute</td>
                        </tr>
                        <tr>
                            <td>got qr code</td>
                            <td>
                                There is a QR code and you need to take a picture of it in the Whatsapp application by
                                going to Menu -&gt; WhatsApp Web -&gt; Add. QR code is valid for one minute.
                                <a
                                    target="_blank"
                                    href="https://ru.stackoverflow.com/questions/358215/%D0%9A%D0%B0%D0%BA-%D0%B2%D1%8B%D0%B2%D0%B5%D1%81%D1%82%D0%B8-%D0%BD%D0%B0-%D1%8D%D0%BA%D1%80%D0%B0%D0%BD-%D0%B8%D0%B7%D0%BE%D0%B1%D1%80%D0%B0%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-base64/358217#358217"
                                >
                                    Example showing base64 images on a page
                                </a>
                                .
                                <br />
                                Manually easier to get
                                <a href="#post_group">QR-code as an image</a>
                            </td>
                        </tr>
                        <tr>
                            <td>authenticated</td>
                            <td>Authorization passed successfully</td>
                        </tr>
                    </tbody>
                </table>

                <h5>Server response example:</h5>

                <SyntaxHighlighter language={'JavaScript'} style={gruvboxDark}>
                    {`{
    "accountStatus": "got qr code",
    "qrCode": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQ....."
}`}
                </SyntaxHighlighter>

                <h3>GET /qr_code</h3>

                <p>Direct link to QR-code in the form of an image, not base64.</p>

                <h3>POST /group</h3>

                <p>
                    Creates a group and sends the message to the created group. If the host is iPhone, then the presence
                    of all in the contact list is required.
                </p>
                <p>
                    The group will be added to the queue for sending and sooner or later it will be created, even if the
                    phone is disconnected from the Internet or the authorization is not passed.
                </p>
                <p>
                    2 Oct 2018 update: chatId parameter will be returned if group was created on your phone within 20
                    seconds.
                </p>

                <p>Request parameters:</p>

                <table>
                    <tbody>
                        <tr>
                            <td>phone</td>
                            <td>Required if chatId is not set</td>
                            <td>
                                A phone number starting with the country code. You do not need to add your number.
                                <br /> USA example: 17472822486.
                            </td>
                        </tr>
                        <tr>
                            <td>chatId</td>
                            <td>Required if phone is not set</td>
                            <td>
                                Chat ID from the message list. Examples: 79633123456@c.us for private messages and
                                79680561234-1479621234@g.us for the group. Used instead of the phone parameter.
                            </td>
                        </tr>
                        <tr>
                            <td>body</td>
                            <td>Required</td>
                            <td>Message text, UTF-8 or UTF-16 string with emoji 🍏</td>
                        </tr>
                    </tbody>
                </table>

                <h5>Request examples:</h5>

                <SyntaxHighlighter language={'JavaScript'} style={gruvboxDark}>
                    {`{
    "phone": "17472822486",
    "body": "Hello, world! 🍏"
}`}
                </SyntaxHighlighter>

                <SyntaxHighlighter language={'JavaScript'} style={gruvboxDark}>
                    {`{
    "chatId": "17472822486@c.us",
    "body": "Hello, world!"
}`}
                </SyntaxHighlighter>

                <h5>Server response example:</h5>

                <SyntaxHighlighter language={'JavaScript'} style={gruvboxDark}>
                    {`{
    "sent": true,
    "message": "ok"
}`}
                </SyntaxHighlighter>

                <h3>POST /sendFile</h3>

                <p>Send a file to a new or existing chat.</p>

                <p>Only one of two parameters is needed to determine the destination - chatId or phone.</p>

                <h5>Request parameters:</h5>

                <table>
                    <tbody>
                        <tr>
                            <td>phone</td>
                            <td>Required if chatId is not set</td>
                            <td>
                                The same as <a href="#post_message">POST /sendMessage</a>
                            </td>
                        </tr>
                        <tr>
                            <td>chatId</td>
                            <td>Required if phone is not set</td>
                            <td>
                                The same as <a href="#post_message">POST /sendMessage</a>
                            </td>
                        </tr>
                        <tr>
                            <td>body</td>
                            <td>Required</td>
                            <td>
                                HTTP link https://upload.wikimedia.org/wikipedia/ru/3/33/NatureCover2001.jpg
                                <br />
                                Or base64-encoded file with mime data, for example
                                data:image/jpeg;base64,/9j/4AAQSkZJRgABAQ...
                                <br />
                                File in form-data input field
                            </td>
                        </tr>
                        <tr>
                            <td>filename</td>
                            <td>Required</td>
                            <td>File name, for example 1.jpg or hello.xlsx</td>
                        </tr>
                        <tr>
                            <td>caption</td>
                            <td>Optional</td>
                            <td>Text under the photo</td>
                        </tr>
                    </tbody>
                </table>

                <h5>Request examples:</h5>

                <SyntaxHighlighter language={'JavaScript'} style={gruvboxDark}>
                    {`{
    "chatId": "17472822486@c.us",
    "body": "https://upload.wikimedia.org/wikipedia/ru/3/33/NatureCover2001.jpg",
    "filename": "cover.jpg"
}`}
                </SyntaxHighlighter>

                <SyntaxHighlighter language={'JavaScript'} style={gruvboxDark}>
                    {`{
    "phone": "17472822486",
    "body": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAMAAACeL25MAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQwIDc5LjE2MDQ1MSwgMjAxNy8wNS8wNi0wMTowODoyMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChNYWNpbnRvc2gpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkI3OEY5RkZENjkwQjExRTg4NENBQjE1QkFGNzEzOEQ5IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkI3OEY5RkZFNjkwQjExRTg4NENBQjE1QkFGNzEzOEQ5Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6Qjc4RjlGRkI2OTBCMTFFODg0Q0FCMTVCQUY3MTM4RDkiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6Qjc4RjlGRkM2OTBCMTFFODg0Q0FCMTVCQUY3MTM4RDkiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz60+8CVAAAADFBMVEX/zc2ampr/ZmYwMDB/wIlPAAAAGUlEQVR42mJgZmQAQkYGBgYmJiYwwQgQYAAAzQAVpgHCIgAAAABJRU5ErkJggg==",
    "filename": "4x4pixel.jpg"
}`}
                </SyntaxHighlighter>

                <h5>Server response example:</h5>

                <SyntaxHighlighter language={'JavaScript'} style={gruvboxDark}>
                    {`{
    "sent": true,
    "message": "ok"
}`}
                </SyntaxHighlighter>

                <h2 id="get_messages">GET /messages</h2>
                <p>
                    Get a list of messages. To receive only new messages, pass the
                    <b>lastMessageNumber</b> parameter from the last query.
                </p>
                <p>
                    Files from messages are guaranteed to be stored only for 30 days and can be deleted. Download the
                    files as soon as you get to your server.
                </p>

                <h5>Request parameters:</h5>

                <table>
                    <tbody>
                        <tr>
                            <td>lastMessageNumber</td>
                            <td>The lastMessageNumber parameter from the last response</td>
                        </tr>
                        <tr>
                            <td>last</td>
                            <td>
                                Displays the last 100 messages. If this parameter is passed, then lastMessageNumber is
                                ignored.
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h5>Server response example:</h5>

                <SyntaxHighlighter language={'JavaScript'} style={gruvboxDark}>
                    {`// GET /messages?lastMessageNumber=99:
{
    "messages:" [{
        //unique id
        "id": "false_17472822486@c.us_DF38E6A25B42CC8CCE57EC40F",
        //text message for type "chat" or link to download the file for "ptt", "image", "audio" and "document"
        "body": "Ok!",
        //type of the message - "chat" - text, "image" - image, "ptt" - voice, "document" - text document, "audio" - audio file, "call_log" - call
        "type": "chat",
        //Sender name
        "senderName": "Ilya",
        //true - outgoing, false - incoming
        "fromMe": true,
        //Author ID of the message, useful for groups
        "author": "17472822486@c.us",
        //send time, unix timestamp
        "time": 1504208593,
        //chat ID
        "chatId": "17472822486@c.us",
        //sequence number of the message in the database
        "messageNumber": 100
    }, {
        //...
    }],
    "lastMessageNumber": 199 //next query should be /messages?lastMessageNumber=199
}`}
                </SyntaxHighlighter>

                <h3 id="post_webhook">POST /webhook</h3>

                <p>
                    Sets the URL for receiving webhook notifications of new messages and message delivery events (ack).
                </p>
                <h5>Request parameters:</h5>

                <table>
                    <tbody>
                        <tr>
                            <td>webhookUrl</td>
                            <td>
                                Http or https URL for receiving notifications. For testing, we recommend using
                                requestb.in.
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h5>Server response example:</h5>

                <SyntaxHighlighter language={'JavaScript'} style={gruvboxDark}>
                    {`{
    "set": true,
    "webhookUrl": "https://requestb.in/1f9aj261"
}`}
                </SyntaxHighlighter>

                <h5>Example of a webhook notification:</h5>

                <SyntaxHighlighter language={'JavaScript'} style={gruvboxDark}>
                    {`{ // It has the same JSON payload as /messages has
  // Sending to POST JSON body
    "messages": [{
        //unique id
        "id": "false_17472822486@c.us_DF38E6A25B42CC8CCE57EC40F",
        //text message for type "chat" or link to download the file for "ptt", "image", "audio" and "document"
        "body": "Ok!",
        //type of the message - "chat" - text, "image" - image, "ptt" - voice, "document" - text document, "audio" - audio file, "call_log" - call
        "type": "chat",
        //Sender name
        "senderName": "Ilya",
        //true - outgoing, false - incoming
        "fromMe": true,
        //Author ID of the message, useful for groups
        "author": "17472822486@c.us",
        //send time, unix timestamp
        "time": 1504208593,
        //chat ID
        "chatId": "17472822486@c.us",
        //sequence number of the message in the database
        "messageNumber": 100
    }, {
        //...
    }],
    "ack": [{//message delivered
        "id": "false_17472822486@c.us_DF38E6A25B42CC8CCE57EC40F",
        "queueNumber": 100
        "chatId": "17472822486@c.us",
        "status": "delivered",
    },{//message viewed
        "id": "false_17472822486@c.us_DF38E6A25B42CC8CCE57EC40F",
        "queueNumber": 100
        "chatId": "17472822486@c.us",
        "status": "viewed",
    }, {
        //...
    }],
}`}
                </SyntaxHighlighter>

                <h3>GET /webhook</h3>

                <p>Returns current webhook url.</p>

                <h5>Server response example:</h5>

                <SyntaxHighlighter language={'JavaScript'} style={gruvboxDark}>
                    {`{
    "set": true,
    "webhookUrl": "https://requestb.in/1f9aj261"
}`}
                </SyntaxHighlighter>

                <h3 id="post_settings_ackNotificationsOn">POST /settings/ackNotificationsOn</h3>

                <p>
                    Turn on/off ack (message delivered and message viewed) notifications in webhooks. GET method works
                    for the same address.{' '}
                </p>
                <h5>Request parameters:</h5>

                <table>
                    <tbody>
                        <tr>
                            <td>ackNotificationsOn</td>
                            <td>1 (true) or 0 (false) .</td>
                        </tr>
                    </tbody>
                </table>

                <h3>GET /logout</h3>

                <p>Logout from WhatsApp Web to get new QR code.</p>

                <h5>Server response example:</h5>

                <SyntaxHighlighter language={'JavaScript'} style={gruvboxDark}>
                    {`{
    "result": "Logout request sent to WhatsApp"
}`}
                </SyntaxHighlighter>

                <h3>GET /reboot</h3>

                <p>Reboot your WhatsApp instance.</p>

                <h5>Server response example:</h5>

                <SyntaxHighlighter language={'JavaScript'} style={gruvboxDark}>
                    {`{
    "success": true
}`}
                </SyntaxHighlighter>

                <h3>GET /showMessagesQueue</h3>

                <p>Get outbound messages queue.</p>

                <h5>Server response example:</h5>

                <SyntaxHighlighter language={'JavaScript'} style={gruvboxDark}>
                    {`{
   "totalMessages":2,
   "first100":[
      {
         "id":1,
         "body":"Hey!",
         "type":"text",
         "chatId":"79600005372@c.us",
         "last_try":1528320463436,
         "metadata":"{}"
      },
   ]
}`}
                </SyntaxHighlighter>

                <h3>GET /clearMessagesQueue</h3>

                <p>
                    Clear outbound messages queue. This method is needed when you accidentally sent thousands of
                    messages in a row.
                </p>

                <h5>Server response example:</h5>

                <SyntaxHighlighter language={'JavaScript'} style={gruvboxDark}>
                    {`{
   "message":"Cleared 2 messages",
   "messageTextsExample":[
      "Hello world",
      "Hello world"
   ]
}`}
                </SyntaxHighlighter>
            </Container>
        </>
    );
};

export default React.memo(ApiDocs);
