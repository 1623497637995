import { ThemeOptions } from '@material-ui/core/styles';

const theme: ThemeOptions = {
    palette: {
        primary: {
            main: '#282828',
            contrastText: '#ebdbb2'
        },
        secondary: {
            main: '#fb4934'
        },
        text: {
            primary: '#282828'
        }
    }
};

export default theme;
