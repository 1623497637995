import { makeAutoObservable, toJS } from 'mobx';
import { getCurrentUser, logInRequest, changePass, createAccount } from '~/api/authApi';

const JWT_NAME = 'jwt';
const CURRENT_USER_MEM_KEY = 'pullCurrentUser';

export type TCurrentUser = {
    email: string;
    apiKey: string;
};

class AuthStore {
    token = '';

    loadingCurrentUser = true;
    currentUser: TCurrentUser | null = null;

    lang: 'en' | 'ru' = 'ru';

    numberFormat: Intl.NumberFormat;

    constructor() {
        this.numberFormat = this.setNumberFormat();

        makeAutoObservable(this);
        const memToken = window.localStorage.getItem(JWT_NAME);
        if (memToken) {
            this.token = memToken;
        }
    }

    setNumberFormat() {
        return Intl.NumberFormat(this.lang, { style: 'currency', currency: this.lang === 'ru' ? 'rub' : 'usd' });
    }

    toggleLanguage = () => {
        this.lang = this.lang === 'en' ? 'ru' : 'en';
        this.numberFormat = this.setNumberFormat();
    };

    pullCurrentUser = async () => {
        if (!this.token) {
            this.logOut();
            return;
        }

        try {
            this.currentUser = await getCurrentUser();
        } catch (error) {
            if (error.code === 403) {
                this.logOut();
            }
        }
    };

    async logIn(email: string, password: string) {
        const token = await logInRequest({ email, password });
        if (token) {
            this.token = token;
            window.localStorage.setItem('jwt', token);
            await this.pullCurrentUser();
        }
    }

    async signUp(email: string, password: string) {
        const token = await createAccount({ email, password });
        if (token) {
            this.token = token;
            window.localStorage.setItem('jwt', token);
            document.location.pathname = '/admin';
        }
    }

    logOut = () => {
        this.token = '';
        this.currentUser = null;
        window.localStorage.removeItem(JWT_NAME);
    };

    loadingChangePass = false;
    errorsChangePass = '';

    changeNewPassword = async (oldPass: string, newPass: string) => {
        try {
            this.loadingChangePass = true;
            await changePass({ oldPass, newPass });
            this.logOut();
        } catch (error) {
            this.errorsChangePass = error;
            this.loadingChangePass = false;
        }
    };

    printCost(cost: number): string {
        return this.numberFormat.format(cost);
    }
}

export default new AuthStore();
