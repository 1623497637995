import React, { useState } from 'react';
import { observer } from 'mobx-react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { gruvboxDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import snippet1 from './snippets/snippet1';
import snippet2 from './snippets/snippet2';
import snippet3 from './snippets/snippet3';
import authStore from '~/stores/authStore';
import Button from '@material-ui/core/Button';
import SignUpBtn from '~/components/auth/SignUpBtn';

export type SnippetTab = {
    code: string;
    title: string;
    language: string;
};

const SnippetTabs = ({ tabs }: { tabs: SnippetTab[] }) => {
    const [tabId, setTabId] = useState(0);
    const handleChangeTabId = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabId(newValue);
    };

    return (
        <>
            <Tabs
                value={tabId}
                onChange={handleChangeTabId}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
            >
                {tabs.map(({ title }) => (
                    <Tab key={title} label={title} />
                ))}
            </Tabs>
            <SyntaxHighlighter language={tabs[tabId].language} style={gruvboxDark}>
                {tabs[tabId].code}
            </SyntaxHighlighter>
        </>
    );
};

const useStyles = makeStyles(({ spacing, palette }) =>
    createStyles({
        root: {
            background: palette.grey[100],
            borderBottom: '2px solid',
            borderBottomColor: palette.grey[300],
            padding: spacing(4)
        },
        bottomMargin: {
            marginTop: spacing(3),
            marginBottom: spacing(3)
        },
        ul: {
            listStyle: 'none'
        }
    })
);

const langLib = {
    en: {
        apiDocs: 'API Docs',
        getApiKey: 'Get an API key'
    },
    ru: {
        apiDocs: 'Документация API',
        getApiKey: 'Получить токен'
    }
} as const;

const CodeSnippet = observer(
    ({ header, paragraph, tabs }: { header: string; paragraph: string; tabs: SnippetTab[] }) => {
        const { lang, currentUser } = authStore;
        const lib = langLib[lang];
        const classes = useStyles();

        return (
            <div className={classes.root}>
                <Container maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h5" className={classes.bottomMargin}>
                                {header}
                            </Typography>
                            <Typography variant="body1" className={classes.bottomMargin}>
                                {paragraph}
                            </Typography>
                            <ul className={classes.ul}>
                                <li>
                                    <Link color="secondary" href="/api">
                                        {lib['apiDocs']}
                                    </Link>
                                </li>
                                {!currentUser && (
                                    <li>
                                        <SignUpBtn>
                                            <Link color="secondary" style={{ cursor: 'pointer' }}>
                                                {lib['getApiKey']}
                                            </Link>
                                        </SignUpBtn>
                                    </li>
                                )}
                            </ul>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <SnippetTabs tabs={tabs} />
                        </Grid>
                    </Grid>
                </Container>
            </div>
        );
    }
);

const CodeSnippets = () => {
    const { lang } = authStore;

    return (
        <>
            <CodeSnippet
                tabs={snippet1}
                header={lang === 'en' ? 'Send a message' : 'Отправить сообщение'}
                paragraph={
                    lang === 'en'
                        ? 'To a phone number connected to WhatsApp or to a group chat.'
                        : 'На телефонный номер, подключенный к WhatsApp или на групповой чат.'
                }
            />
            <CodeSnippet
                tabs={snippet2}
                header={lang === 'en' ? 'Read messages' : 'Прочитать сообщения'}
                paragraph={
                    lang === 'en'
                        ? 'History of WhatsApp conversations in personal and group chats, including before connecting the API.'
                        : 'История разговоров в Whatsapp в персональных и групповых чатах, включая историю до подключения API'
                }
            />
            <CodeSnippet
                tabs={snippet3}
                header={lang === 'en' ? 'Set a Webhook' : 'Установить веб-хуки'}
                paragraph={
                    lang === 'en'
                        ? 'Receive notifications about personal and group messages through incoming http requests to your server. This is the main function for creating a chatbot.'
                        : 'Получение уведомлений о персональных и групповых сообщениях использую вебхуки в реально времени. Это основной функционал чатбота.'
                }
            />
        </>
    );
};

export default observer(CodeSnippets);
