const codes = [
    {
        title: 'php',
        language: 'php',
        code: `
$data = [
    'phone' => '79995253422', // Receivers phone
    'body' => 'Hello, Andrew!', // Message
];
$json = json_encode($data); // Encode data to JSON
// URL for request POST /message
$token = '83763g87x';
$instanceId = '777';
$url = 'https://api.connect-api.com/instance'.$instanceId.'/message?token='.$token;
// Make a POST request
$options = stream_context_create(['http' => [
        'method'  => 'POST',
        'header'  => 'Content-type: application/json',
        'content' => $json
    ]
]);
// Send a request
$result = file_get_contents($url, false, $options);
`
    },
    {
        title: 'node.js',
        language: 'javascript',
        code: `
var request = require('request'); //bash: npm install request
// URL for request POST /message
var token = '83763g87x';
var instanceId = '777';
var url = \`https://api.connect-api.com/instance\${instanceId}/message?token=\${token}\`;
var data = {
    phone: '79995253422', // Receivers phone
    body: 'Hello, Andrew!', // Сообщение
};
// Send a request
request({
    url: url,
    method: "POST",
    json: data
});
`
    },
    {
        title: 'jquery',
        language: 'javascript',
        code: `
// URL for request POST /message
var token = '83763g87x';
var instanceId = '777';
var url = \`https://api.connect-api.com/instance\${instanceId}/message?token=\${token}\`;
var data = {
    phone: '79995253422', // Receivers phone
    body: 'Hello, Andrew!', // Message
};
// Send a request
$.ajax(url, {
    data : JSON.stringify(data),
    contentType : 'application/json',
    type : 'POST'
});
`
    },
    {
        title: 'curl (bash)',
        language: 'bash',
        code: `
curl \\
-d '{"phone": "79995253422","body": "Hello, Andrew!"}' \\ # Phone and message
-H "Content-Type: application/json" \\ # Headers
-X POST \\ # Type = POST
"https://api.connect-api.com/instance777/message?token=83763g87x" # URL for request POST /message
`
    }
];

export default codes;
