import React, { useState, ChangeEvent } from 'react';
import { observer } from 'mobx-react';
import { createStyles, makeStyles, styled } from '@material-ui/core/styles';

import adminStore from '~/stores/adminStore';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import Button from '@material-ui/core/Button';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';

import OrderModal from './OrderModal';
import authStore from '~/stores/authStore';
import Hidden from '@material-ui/core/Hidden';

const WhatsappToggleBtn = styled(ToggleButton)({
    '&.Mui-selected, &.Mui-selected:hover': {
        color: 'white',
        backgroundColor: '#25D366',
        paddingRight: '1rem',
        paddingLeft: '1rem'
    }
});

const TelegramToggleBtn = styled(ToggleButton)({
    '&.Mui-selected, &.Mui-selected:hover': {
        color: 'white',
        backgroundColor: '#0088cc',
        paddingRight: '1rem',
        paddingLeft: '1rem'
    }
});

const BothToggleBtn = styled(ToggleButton)({
    '&.Mui-selected, &.Mui-selected:hover': {
        color: 'white',
        background: 'linear-gradient(90deg, #25D366, #0088cc);',
        paddingRight: '1rem',
        paddingLeft: '1rem'
    }
});

type PriceItem = { numbers: number; priceW: number; priceT: number; priceWT: number };

export const prices: Readonly<{
    en: PriceItem[];
    ru: PriceItem[];
}> = {
    en: [
        { numbers: 0, priceW: 49.99, priceT: 29.99, priceWT: 59.99 },
        { numbers: 1, priceW: 49.99, priceT: 29.99, priceWT: 59.99 },
        { numbers: 5, priceW: 39.99, priceT: 19.99, priceWT: 49.99 },
        { numbers: 10, priceW: 29.99, priceT: 14.99, priceWT: 39.99 },
        { numbers: 20, priceW: 19.99, priceT: 12.99, priceWT: 29.99 },
        { numbers: 50, priceW: 15.99, priceT: 11.99, priceWT: 22.99 },
        { numbers: 100, priceW: 13.99, priceT: 9.99, priceWT: 19.99 },
        { numbers: 200, priceW: 12.99, priceT: 8.99, priceWT: 17.99 },
        { numbers: 1000, priceW: 11.49, priceT: 7.49, priceWT: 15.49 }
    ],
    ru: [
        { numbers: 0, priceW: 2999, priceT: 1999, priceWT: 3999 },
        { numbers: 1, priceW: 2999, priceT: 1999, priceWT: 3999 },
        { numbers: 5, priceW: 1999, priceT: 1499, priceWT: 2999 },
        { numbers: 10, priceW: 1599, priceT: 1099, priceWT: 1999 },
        { numbers: 20, priceW: 1299, priceT: 899, priceWT: 1599 },
        { numbers: 50, priceW: 1099, priceT: 699, priceWT: 1499 },
        { numbers: 100, priceW: 999, priceT: 549, priceWT: 1399 },
        { numbers: 200, priceW: 949, priceT: 499, priceWT: 1199 },
        { numbers: 1000, priceW: 899, priceT: 399, priceWT: 1099 }
    ]
};

export const matchPrice = (
    lang: 'en' | 'ru',
    priceKey: 'priceW' | 'priceT' | 'priceWT',
    phoneNumbers: number
): number => {
    const pricesLang = prices[lang];
    return pricesLang.reduce((acc, { numbers, [priceKey]: price }) => {
        if (phoneNumbers >= numbers) {
            return price;
        }
        return acc;
    }, 0);
};

const useStyles = makeStyles(({ palette, spacing }) =>
    createStyles({
        input: {
            width: '50%'
        }
    })
);

const langLib = {
    en: {
        addWhatsapp: 'Add numbers',
        price: 'Price, $',
        totalCost: 'Total Cost, $',
        currency: '$',
        order: 'Order'
    },
    ru: {
        addWhatsapp: 'Добавить номера',
        price: 'Цена, руб.',
        totalCost: 'Сумма, руб.',
        currency: 'руб.',
        order: 'Заказать'
    }
} as const;

const Calculator = () => {
    const classes = useStyles();
    const { phoneNumbers, priceType } = adminStore;
    const { lang } = authStore;
    const lib = langLib[lang];

    const handleSliderChange = (event: any, newValue: number | number[]) => {
        adminStore.setPhoneNumbers(typeof newValue === 'number' ? newValue : 0);
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        adminStore.setPhoneNumbers(event.target.value === '' ? 0 : Number(event.target.value));
    };

    const handleBlur = () => {
        if (phoneNumbers < 0) {
            adminStore.setPhoneNumbers(0);
        } else if (phoneNumbers > 2000) {
            adminStore.setPhoneNumbers(2000);
        }
    };

    const [showOrderModal, setShowOrderModal] = useState(false);
    const handleOrderModal = () => {
        setShowOrderModal(!showOrderModal);
    };

    return (
        <>
            <Box alignItems="center" display="flex" style={{ gap: '1rem', marginBottom: '3rem' }}>
                <Typography gutterBottom>{lib['addWhatsapp']}</Typography>
                <ToggleButtonGroup
                    size="small"
                    value={priceType}
                    exclusive
                    onChange={(_, value) => value && adminStore.setPriceType(value)}
                >
                    <WhatsappToggleBtn value={'priceW'}>Whatsapp</WhatsappToggleBtn>
                    <TelegramToggleBtn value={'priceT'}>Telegram</TelegramToggleBtn>
                    <BothToggleBtn value={'priceWT'}>
                        <Hidden xsDown>Whatsapp + Telegram</Hidden>
                        <Hidden smUp>Whapp+Tg</Hidden>
                    </BothToggleBtn>
                </ToggleButtonGroup>
            </Box>

            <Grid container spacing={2} alignItems="center">
                <Grid item xs={1}>
                    <PhoneIphoneIcon />
                </Grid>
                <Grid item xs={3}>
                    <Input
                        className={classes.input}
                        value={phoneNumbers}
                        margin="dense"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        inputProps={{
                            step: 1,
                            min: 0,
                            type: 'number'
                        }}
                    />
                </Grid>
                <Grid item xs={8}>
                    <Slider
                        color="secondary"
                        value={phoneNumbers}
                        onChange={handleSliderChange}
                        max={1000}
                        valueLabelDisplay="on"
                    />
                </Grid>

                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel>{lib['price']}</InputLabel>
                        <Input
                            value={matchPrice(lang, priceType, phoneNumbers)}
                            readOnly
                            startAdornment={<InputAdornment position="start">{lib['currency']}</InputAdornment>}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel>{lib['totalCost']}</InputLabel>
                        <Input
                            value={(phoneNumbers * matchPrice(lang, priceType, phoneNumbers)).toLocaleString('ru')}
                            readOnly
                            startAdornment={<InputAdornment position="start">{lib['currency']}</InputAdornment>}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <Button
                            disabled={phoneNumbers === 0}
                            onClick={handleOrderModal}
                            color="secondary"
                            variant="contained"
                            startIcon={<ShoppingCartIcon />}
                        >
                            {lib['order']}
                        </Button>
                        {showOrderModal && <OrderModal onClose={handleOrderModal} />}
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
};

export default observer(Calculator);
