import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import authStore from '~/stores/authStore';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import TextField from '@material-ui/core/TextField';
import ChangePassword from './ChangePassword';

const useStyles = makeStyles(({ palette, spacing }) =>
    createStyles({
        alignCenter: {
            display: 'flex',
            alignItems: 'center'
        }
    })
);

const langLib = {
    en: {
        country: 'Country',
        logOut: 'Log Out'
    },
    ru: {
        country: 'Страна',
        logOut: 'Выход'
    }
} as const;

const Profile = () => {
    const classes = useStyles();
    const { lang, currentUser } = authStore;
    const lib = langLib[lang];

    return (
        <Grid container spacing={3}>
            <Grid item xs={6} className={classes.alignCenter}>
                <TextField
                    label="Email"
                    variant="standard"
                    inputProps={{ readOnly: true }}
                    value={currentUser?.email}
                    fullWidth
                />
            </Grid>
            <Grid item xs={6} className={classes.alignCenter}>
                <TextField
                    label={lib['country']}
                    variant="standard"
                    inputProps={{ readOnly: true }}
                    value={'Russian Federation'}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} className={classes.alignCenter}>
                <ChangePassword />
            </Grid>
            <Grid item xs={12} className={classes.alignCenter}>
                <Button startIcon={<ExitToAppIcon />} color="default" variant="outlined" onClick={authStore.logOut}>
                    {lib['logOut']}
                </Button>
            </Grid>
        </Grid>
    );
};

export default observer(Profile);
