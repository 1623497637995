import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LockIcon from '@material-ui/icons/Lock';
import TouchAppIcon from '@material-ui/icons/TouchApp';

import SDKLogos from './SdkLogos';
import Banner from '~/components/Banner';
import MainParallax from '~/components/Home/MainParallax';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) =>
    createStyles({
        text: {
            color: palette.background.paper,
            textShadow: `1px 1px 1px ${palette.primary.dark}`
        },
        aboutCard: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            flexDirection: 'column',
            margin: spacing(4, 0),
            [breakpoints.up('md')]: {
                margin: spacing(10, 0)
            },
            '& > *': {
                margin: spacing(2, 0)
            }
        }
    })
);

const SDK = () => {
    const classes = useStyles();

    return (
        <>
            <Banner style={{ height: '160px' }}>
                <Typography variant="h3" component="h1" className={classes.text} align="center">
                    Whatsapp Api SDKs
                </Typography>
            </Banner>
            <SDKLogos />
            <Container maxWidth="md">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} className={classes.aboutCard}>
                        <ShoppingCartIcon fontSize="large" color="secondary" />
                        <Typography variant="h5">Getting Started</Typography>
                        <Typography variant="body2" align="center">
                            A step-by-step tutorial for getting started with the SDKs, right from installing to making
                            your first API call.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={4} className={classes.aboutCard}>
                        <LockIcon fontSize="large" color="secondary" />
                        <Typography variant="h5">Authentication</Typography>
                        <Typography variant="body2" align="center">
                            Easily authenticate the client for making calls to the server.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={4} className={classes.aboutCard}>
                        <TouchAppIcon fontSize="large" color="secondary" />
                        <Typography variant="h5">SDKs & Code Samples</Typography>
                        <Typography variant="body2" align="center">
                            Code Samples are the quickest path to the first hello world, while SDKs lift the heavy
                            burden of communication with an API.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
            <MainParallax />
        </>
    );
};

export default SDK;
